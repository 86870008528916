import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
    Avatar,
    Tooltip,
    Grid,
    FormControlLabel,
    Switch
} from '@mui/material';
import { styled } from '@mui/material/styles';

import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    Save as SaveIcon,
    Cancel as CancelIcon
} from '@mui/icons-material';

// API endpoints
const API_ENDPOINTS = {
    USERS: '/api/users',
    USER: (id) => `/api/users/${id}`
};

const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#53B146',
        '&:hover': {
            backgroundColor: 'rgba(83, 177, 70, 0.08)',
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#53B146',
    },
}));

const UserManagement = () => {
    // State management
    const [users, setUsers] = useState([]);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [editingUser, setEditingUser] = useState(null);
    const [newUser, setNewUser] = useState({
        firstName: '',
        lastName: '',
        username: '',
        role: 'Employee',
        isActive: true
    });
    const [loading, setLoading] = useState(false);

    const roles = ['ITIL', 'Admin', 'Approver', 'Employee'];

    // Fetch users on component mount
    useEffect(() => {
        fetchUsers();
    }, []);

    // Fetch users from API
    const fetchUsers = async () => {
        setLoading(true);
        try {
            // In a real app, this would be an actual API call
            // const response = await axios.get(API_ENDPOINTS.USERS);
            // setUsers(response.data);

            // Dummy data for demonstration
            setTimeout(() => {
                const dummyUsers = [
                    {
                        id: 1,
                        firstName: 'John',
                        lastName: 'Doe',
                        username: 'john.doe@example.com',
                        role: 'Admin',
                        isActive: true,
                        avatar: `https://ui-avatars.com/api/?name=John+Doe&background=random`
                    },
                    {
                        id: 2,
                        firstName: 'Jane',
                        lastName: 'Smith',
                        username: 'jane.smith@example.com',
                        role: 'ITIL',
                        isActive: true,
                        avatar: `https://ui-avatars.com/api/?name=Jane+Smith&background=random`
                    },
                    {
                        id: 3,
                        firstName: 'Bob',
                        lastName: 'Johnson',
                        username: 'bob.johnson@example.com',
                        role: 'Employee',
                        isActive: false,
                        avatar: `https://ui-avatars.com/api/?name=Bob+Johnson&background=random`
                    }
                ];
                setUsers(dummyUsers);
                setLoading(false);
            }, 500);
        } catch (error) {
            console.error('Error fetching users:', error);
            setLoading(false);
        }
    };

    // Handlers for user operations
    const handleAddUser = async () => {
        try {
            // Check if the form has all necessary data
            if (!newUser.firstName || !newUser.lastName || !newUser.username) {
                alert('Please fill all required fields');
                return;
            }

            setLoading(true);

            // In a real app, this would be an actual API call
            // const response = await axios.post(API_ENDPOINTS.USERS, newUser);
            // const addedUser = response.data;

            // Simulate API call
            setTimeout(() => {
                const addedUser = {
                    ...newUser,
                    id: users.length + 1,
                    avatar: `https://ui-avatars.com/api/?name=${newUser.firstName}+${newUser.lastName}&background=random`
                };

                setUsers([...users, addedUser]);
                setOpenAddModal(false);
                setNewUser({
                    firstName: '',
                    lastName: '',
                    username: '',
                    role: 'Employee',
                    isActive: true
                });
                setLoading(false);
            }, 500);
        } catch (error) {
            console.error('Error adding user:', error);
            setLoading(false);
        }
    };

    const handleUpdateUser = async (user) => {
        try {
            setLoading(true);

            // In a real app, this would be an actual API call
            // await axios.put(API_ENDPOINTS.USER(user.id), user);

            // Simulate API call
            setTimeout(() => {
                setUsers(users.map(u => u.id === user.id ? user : u));
                setEditingUser(null);
                setLoading(false);
            }, 500);
        } catch (error) {
            console.error('Error updating user:', error);
            setLoading(false);
        }
    };

    const handleDeleteUser = async (userId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this user?');
        if (!confirmDelete) return;

        try {
            setLoading(true);

            // In a real app, this would be an actual API call
            // await axios.delete(API_ENDPOINTS.USER(userId));

            // Simulate API call
            setTimeout(() => {
                setUsers(users.filter(user => user.id !== userId));
                setLoading(false);
            }, 500);
        } catch (error) {
            console.error('Error deleting user:', error);
            setLoading(false);
        }
    };

    const handleRoleChange = async (userId, newRole) => {
        const userToUpdate = users.find(user => user.id === userId);
        if (!userToUpdate) return;

        try {
            setLoading(true);

            // In a real app, this would be an actual API call
            // await axios.patch(API_ENDPOINTS.USER(userId), { role: newRole });

            // Simulate API call
            setTimeout(() => {
                setUsers(users.map(user => {
                    if (user.id === userId) {
                        return { ...user, role: newRole };
                    }
                    return user;
                }));
                setLoading(false);
            }, 300);
        } catch (error) {
            console.error('Error updating user role:', error);
            setLoading(false);
        }
    };

    const handleNewUserChange = (e) => {
        const { name, value, checked } = e.target;
        const val = name === 'isActive' ? checked : value;
        setNewUser({ ...newUser, [name]: val });
    };

    const handleEditingUserChange = (e) => {
        const { name, value, checked } = e.target;
        const val = name === 'isActive' ? checked : value;
        setEditingUser({ ...editingUser, [name]: val });
    };

    return (
        <Container maxWidth="lg">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <h3>
                    Users
                </h3>
                <Button
                    variant="outlined"
                    sx={{ color: "#53B146", borderColor: "#53B146" }}
                    startIcon={<AddIcon />}
                    onClick={() => setOpenAddModal(true)}
                    disabled={loading}
                >
                    Add User
                </Button>
            </Box>

            <List>
                {users.map((user) => (
                    <ListItem
                        key={user.id}
                        alignItems="flex-start"
                        component={Paper}
                        elevation={1}
                        sx={{
                            mb: 2,
                            p: 2,
                            opacity: user.isActive ? 1 : 0.5,
                            backgroundColor: user.isActive ? 'white' : '#f5f5f5'
                        }}
                    >
                        {editingUser && editingUser.id === user.id ? (
                            // Edit mode for user
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} md={1}>
                                    <Avatar src={user.avatar} alt={`${user.firstName} ${user.lastName}`} />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        fullWidth
                                        label="First Name"
                                        name="firstName"
                                        value={editingUser.firstName}
                                        onChange={handleEditingUserChange}
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        fullWidth
                                        label="Last Name"
                                        name="lastName"
                                        value={editingUser.lastName}
                                        onChange={handleEditingUserChange}
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        label="Username (Email)"
                                        name="username"
                                        value={editingUser.username}
                                        onChange={handleEditingUserChange}
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <FormControl fullWidth margin="dense">
                                        <InputLabel>Role</InputLabel>
                                        <Select
                                            name="role"
                                            value={editingUser.role}
                                            onChange={handleEditingUserChange}
                                            label="Role"
                                        >
                                            {roles.map(role => (
                                                <MenuItem key={role} value={role}>
                                                    {role}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={1}>
                                    <FormControlLabel
                                        control={
                                            <GreenSwitch
                                                checked={editingUser.isActive}
                                                onChange={handleEditingUserChange}
                                                name="isActive"
                                            />
                                        }
                                        label="Active"
                                    />
                                </Grid>
                                <Grid item xs={6} md={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Tooltip title="Save">
                                        <IconButton
                                            color="#53B146"
                                            onClick={() => handleUpdateUser(editingUser)}
                                            disabled={loading}
                                        >
                                            <SaveIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Cancel">
                                        <IconButton
                                            color="secondary"
                                            onClick={() => setEditingUser(null)}
                                            disabled={loading}
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        ) : (
                            // View mode for user
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} md={1}>
                                    <ListItemAvatar>
                                        <Avatar src={user.avatar} alt={`${user.firstName} ${user.lastName}`} />
                                    </ListItemAvatar>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <ListItemText primary={`${user.firstName} ${user.lastName}`} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <ListItemText secondary={user.username} />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <FormControl fullWidth margin="dense">
                                        <InputLabel>Role</InputLabel>
                                        <Select
                                            value={user.role}
                                            onChange={(e) => handleRoleChange(user.id, e.target.value)}
                                            label="Role"
                                        >
                                            {roles.map(role => (
                                                <MenuItem key={role} value={role}>
                                                    {role}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={2}>
                                    <Typography variant="body2" color={user.isActive ? "success.main" : "text.disabled"}>
                                        {user.isActive ? 'Active' : 'Inactive'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Tooltip title="Edit">
                                        <IconButton
                                            onClick={() => setEditingUser({ ...user })}
                                            disabled={loading}
                                        >
                                            <EditIcon sx={{ color: '#53B146' }} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <IconButton
                                            color="rgba(0, 0, 0, 0.6)"
                                            onClick={() => handleDeleteUser(user.id)}
                                            disabled={loading}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        )}
                    </ListItem>
                ))}
            </List>

            {/* Add User Modal */}
            <Dialog
                open={openAddModal}
                onClose={() => setOpenAddModal(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Add New User</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="firstName"
                        label="First Name"
                        type="text"
                        fullWidth
                        value={newUser.firstName}
                        onChange={handleNewUserChange}
                        required
                    />
                    <TextField
                        margin="dense"
                        name="lastName"
                        label="Last Name"
                        type="text"
                        fullWidth
                        value={newUser.lastName}
                        onChange={handleNewUserChange}
                        required
                    />
                    <TextField
                        margin="dense"
                        name="username"
                        label="Username (Email)"
                        type="email"
                        fullWidth
                        value={newUser.username}
                        onChange={handleNewUserChange}
                        required
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Role</InputLabel>
                        <Select
                            name="role"
                            value={newUser.role}
                            onChange={handleNewUserChange}
                            label="Role"
                        >
                            {roles.map(role => (
                                <MenuItem key={role} value={role}>
                                    {role}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={newUser.isActive}
                                onChange={handleNewUserChange}
                                name="isActive"
                            />
                        }
                        label="Active"
                        margin="dense"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenAddModal(false)} sx={{ color: "rgba(0, 0, 0, 0.6)" }} variant="outlined" disabled={loading}>
                        Cancel
                    </Button>
                    <Button onClick={handleAddUser} sx={{ color: "#53B146", borderColor: "#53B146" }} variant="outlined" disabled={loading}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default UserManagement;