import React, { useState, useEffect } from 'react';
import ChatWidget from '../components/ChatWidget';
import PrimarySearchAppBar from '../components/PrimarySearchAppBar';
import Dashboard from '../components/Dashboard/Dashboard';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import { FormControl, InputLabel, MenuItem, Paper, Select } from '@mui/material';
import SNowListing from '../components/SNowListing';
import HomeMain from '../components/HomeMain';
import { useSearchParams } from 'react-router-dom';

const ChatWidgetWMemo = React.memo(ChatWidget)

const HomePage = () => {
  // This is fine as it's not triggering a re-render
  localStorage.setItem('ea_conv_id', null)
  console.log("ea conv id in home page::: ", localStorage.getItem('ea_conv_id'))
  
  const [menuSelected, setMenuSelected] = useState({});
  const [isSearch, setIsSearch] = useState(true);
  const [searchParams] = useSearchParams();
  
  // Move the query param logic to useEffect
  useEffect(() => {
    const type = searchParams.get('type');
    const section = searchParams.get('section');
    const main = searchParams.get('main');
    
    if (type && section) {
      setMenuSelected({"type": type, "section": section});
      setIsSearch(false);
    }
    else if (main === 'true'){
      setIsSearch(true);
    }
  }, [searchParams]); // Only run when searchParams changes

  function handleMenuSelection(data) {
    setMenuSelected(data);
    console.log(data)
    if (data.type === 'home') {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
  }

  return (
    <div>
      <header>
        <PrimarySearchAppBar menuSelect={handleMenuSelection} pageName="home"></PrimarySearchAppBar>
      </header>
      <Box component="main" sx={{
        flexGrow: 1,
        padding: '1%',
        paddingLeft: 0,
        marginTop: 0,
        marginBottom: 0,
        marginRight: 0,
        marginLeft: {
          xs: '50px', // Extra small screens - collapsed drawer
          sm: '192px', // Small/medium screens
          md: '195px', // Desktop size
          lg: '198px'  // Large screens
        },
        width: 'auto',
        overflow: 'hidden',
        transition: (theme) => theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }}>
        <Grid container spacing={2}>
          <Grid size={7} >
            {isSearch && <Paper elevation={2}><HomeMain /></Paper>}
            {!isSearch && <Paper elevation={2} sx={{height:'100%'}} ><SNowListing taskType={menuSelected.type} section={menuSelected.section} /></Paper>}
          </Grid>
          <Grid size={5} >
            <ChatWidgetWMemo botName='employeeAssist' entityType="" entity="" />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default HomePage;