import React, { useState } from 'react';
import { 
  Box,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CssBaseline
} from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import PeopleIcon from '@mui/icons-material/People';
import PrimarySearchAppBar from '../components/PrimarySearchAppBar';
import UserManagement from './UserManagement';
import AppConnections from './AppConnections';

const drawerWidth = 240;

function Settings() {
  const [selectedMenuItem, setSelectedMenuItem] = useState('External Apps');

  const handleMenuItemClick = (text) => {
    setSelectedMenuItem(text);
  };

  // Map menu items to their corresponding components
  const getComponentForMenuItem = (menuItem) => {
    switch(menuItem) {
      case 'External Apps':
        return <AppConnections />;
      case 'Users Management':
        return <UserManagement />;
      default:
        return <div>Select a menu item</div>;
    }
  };

  const menuItems = [
    { text: 'External Apps', icon: <AppsIcon /> },
    { text: 'Users Management', icon: <PeopleIcon /> }
  ];

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <header>
              <PrimarySearchAppBar pageName="settings"></PrimarySearchAppBar>
            </header>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { 
            width: drawerWidth, 
            boxSizing: 'border-box' 
          },
        }}
      >
        <Toolbar /> {/* This creates space for the AppBar */}
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {menuItems.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton
                  selected={selectedMenuItem === item.text}
                  onClick={() => handleMenuItemClick(item.text)}
                >
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ 
          flexGrow: 1, 
          p: 3 
        }}
      >
        <Toolbar /> {/* This creates space below the AppBar */}
        {getComponentForMenuItem(selectedMenuItem)}
      </Box>
    </Box>
  );
}

export default Settings;