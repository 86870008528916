import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Box, 
  Typography, 
  Card, 
  CardContent, 
  Grid, 
  Switch, 
  FormControlLabel, 
  Button, 
  Snackbar, 
  Alert, 
  CircularProgress,
  Avatar,
  CardHeader,
  Modal,
  TextField,
  Stack,
  IconButton,
  Paper,
  Divider,
  createTheme,
  ThemeProvider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ComputerIcon from '@mui/icons-material/Computer';
import BugReportIcon from '@mui/icons-material/BugReport';
import CloudIcon from '@mui/icons-material/Cloud';
import GroupsIcon from '@mui/icons-material/Groups';
import ChatIcon from '@mui/icons-material/Chat';
import CodeIcon from '@mui/icons-material/Code';
import EditIcon from '@mui/icons-material/Edit';

// Custom theme with green primary color
const theme = createTheme({
  palette: {
    primary: {
      main: '#53B146',
    },
  },
});

// Custom styled Switch with the specified color
const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#53B146',
    '&:hover': {
      backgroundColor: 'rgba(83, 177, 70, 0.08)',
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#53B146',
  },
}));

// Modal style
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2
};

// Custom styled IconButton with green color
const GreenIconButton = styled(IconButton)({
  color: '#53B146',
});

const AppConnections = () => {
  const [apps, setApps] = useState([]);
  const [originalApps, setOriginalApps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // State for credential modal
  const [modalOpen, setModalOpen] = useState(false);
  const [currentAppIndex, setCurrentAppIndex] = useState(null);
  const [credentialForm, setCredentialForm] = useState({
    apiKey: '',
    username: '',
    password: '',
    url: ''
  });

  // Map of icon names to their actual components
  const iconComponents = {
    computerIcon: <ComputerIcon />,
    bugReportIcon: <BugReportIcon />,
    cloudIcon: <CloudIcon />,
    groupsIcon: <GroupsIcon />,
    chatIcon: <ChatIcon />,
    codeIcon: <CodeIcon />
  };

  useEffect(() => {
    fetchApps();
  }, []);

  const fetchApps = async () => {
    setLoading(true);
    try {
      // In a real app, replace this URL with your actual API endpoint
      //const response = await axios.get('https://api.example.com/apps');
      
      // For demo purposes, we'll use our example data with icons included in the data
      const dummyData = [
        {
          "title": "Service Now", 
          "status": true, 
          "iconName": "computerIcon", 
          "iconColor": "#ff9800",
          "credentials": {
            "apiKey": "",
            "username": "serviceadmin",
            "password": "",
            "url": "https://servicenow.example.com"
          }
        },
        {
          "title": "JIRA", 
          "status": false, 
          "iconName": "bugReportIcon", 
          "iconColor": "#2196f3",
          "credentials": {
            "apiKey": "",
            "username": "",
            "password": "",
            "url": ""
          }
        },
        {
          "title": "Salesforce", 
          "status": true, 
          "iconName": "cloudIcon", 
          "iconColor": "#00bcd4",
          "credentials": {
            "apiKey": "sf_api_12345",
            "username": "admin@example.com",
            "password": "********",
            "url": "https://salesforce.example.com"
          }
        },
        {
          "title": "Microsoft Teams", 
          "status": true, 
          "iconName": "groupsIcon", 
          "iconColor": "#673ab7",
          "credentials": {
            "apiKey": "ms_teams_key",
            "username": "",
            "password": "",
            "url": "https://teams.microsoft.com"
          }
        },
        {
          "title": "Slack", 
          "status": false, 
          "iconName": "chatIcon", 
          "iconColor": "#e91e63",
          "credentials": {
            "apiKey": "",
            "username": "",
            "password": "",
            "url": ""
          }
        },
        {
          "title": "GitHub", 
          "status": true, 
          "iconName": "codeIcon", 
          "iconColor": "#333333",
          "credentials": {
            "apiKey": "github_pat_token",
            "username": "devuser",
            "password": "",
            "url": "https://api.github.com"
          }
        }
      ];
      
      setApps(dummyData);
      setOriginalApps(JSON.parse(JSON.stringify(dummyData)));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching apps:', error);
      setSnackbar({
        open: true,
        message: 'Failed to load apps. Please try again later.',
        severity: 'error'
      });
      setLoading(false);
    }
  };

  const handleStatusChange = (index, newStatus) => {
    const updatedApps = [...apps];
    updatedApps[index].status = newStatus;
    setApps(updatedApps);
  };

  const openCredentialModal = (index) => {
    setCurrentAppIndex(index);
    setCredentialForm(apps[index].credentials);
    setModalOpen(true);
  };

  const closeCredentialModal = () => {
    setModalOpen(false);
    setCurrentAppIndex(null);
  };

  const handleCredentialChange = (e) => {
    const { name, value } = e.target;
    setCredentialForm({
      ...credentialForm,
      [name]: value
    });
  };

  const saveCredentials = () => {
    if (currentAppIndex === null) return;
    
    const updatedApps = [...apps];
    updatedApps[currentAppIndex].credentials = credentialForm;
    setApps(updatedApps);
    
    setModalOpen(false);
    setSnackbar({
      open: true,
      message: `Credentials updated for ${apps[currentAppIndex].title}`,
      severity: 'success'
    });
  };

  const saveChanges = async () => {
    setSaving(true);
    try {
      // In a real app, replace this URL with your actual API endpoint
      await axios.put('https://api.example.com/apps', apps);
      
      // For demo purposes, we'll just simulate an API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      setOriginalApps(JSON.parse(JSON.stringify(apps)));
      setSnackbar({
        open: true,
        message: 'Apps configuration saved successfully!',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error saving apps:', error);
      setSnackbar({
        open: true,
        message: 'Failed to save changes. Please try again.',
        severity: 'error'
      });
    } finally {
      setSaving(false);
    }
  };

  const cancelChanges = () => {
    setApps(JSON.parse(JSON.stringify(originalApps)));
    setSnackbar({
      open: true,
      message: 'Changes canceled',
      severity: 'info'
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const hasChanges = () => {
    return JSON.stringify(apps) !== JSON.stringify(originalApps);
  };

  // Get the icon component based on the iconName in the data
  const getIconComponent = (iconName) => {
    return iconComponents[iconName] || null;
  };

  // Check if app has credentials configured
  const hasCredentials = (app) => {
    return app.credentials.apiKey || app.credentials.username || app.credentials.url;
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Typography variant="h5" component="h3" gutterBottom>
          Apps
        </Typography>
        
        {loading ? (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Grid container spacing={3} sx={{ mb: 4 }}>
              {apps.map((app, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card sx={{ height: '100%' }}>
                    <CardHeader
                      avatar={
                        <Avatar sx={{ bgcolor: app.iconColor || '#757575' }}>
                          {getIconComponent(app.iconName) || app.title.charAt(0)}
                        </Avatar>
                      }
                      title={app.title}
                      action={
                        app.status && (
                          <GreenIconButton 
                            aria-label="edit credentials"
                            onClick={() => openCredentialModal(index)}
                          >
                            <EditIcon />
                          </GreenIconButton>
                        )
                      }
                    />
                    <CardContent>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <FormControlLabel
                          control={
                            <GreenSwitch
                              checked={app.status}
                              onChange={(e) => handleStatusChange(index, e.target.checked)}
                            />
                          }
                          label={app.status ? "ON" : "OFF"}
                        />
                        {app.status && hasCredentials(app) && (
                          <Box 
                            sx={{ 
                              display: 'inline-flex', 
                              backgroundColor: 'rgba(83, 177, 70, 0.1)', 
                              color: '#53B146',
                              px: 1, 
                              py: 0.5, 
                              borderRadius: 1,
                              fontSize: '0.75rem',
                              fontWeight: 'medium'
                            }}
                          >
                            Connected
                          </Box>
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>

            <Box display="flex" justifyContent="flex-end" gap={2}>
              <Button 
                variant="outlined" 
                onClick={cancelChanges}
                disabled={!hasChanges() || saving}
                sx={{ borderColor: '#53B146', color: '#53B146', '&:hover': { borderColor: '#45a139' } }}
              >
                Cancel
              </Button>
              <Button 
                variant="contained" 
                onClick={saveChanges}
                disabled={!hasChanges() || saving}
                sx={{ 
                  bgcolor: '#53B146', 
                  '&:hover': { bgcolor: '#45a139' },
                  '&.Mui-disabled': { bgcolor: 'rgba(83, 177, 70, 0.12)' } 
                }}
              >
                {saving ? <CircularProgress size={24} color="inherit" /> : 'Save Changes'}
              </Button>
            </Box>
          </>
        )}

        {/* Credentials Modal */}
        <Modal
          open={modalOpen}
          onClose={closeCredentialModal}
          aria-labelledby="credential-modal-title"
        >
          <Paper sx={modalStyle}>
            <Typography id="credential-modal-title" variant="h6" component="h2" gutterBottom>
              {currentAppIndex !== null ? `Edit ${apps[currentAppIndex]?.title} Credentials` : 'Edit Credentials'}
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Stack spacing={2}>
              <TextField
                label="API Key"
                name="apiKey"
                value={credentialForm.apiKey}
                onChange={handleCredentialChange}
                fullWidth
                variant="outlined"
                size="small"
                InputProps={{
                  sx: { '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#53B146' } }
                }}
                InputLabelProps={{
                  sx: { '&.Mui-focused': { color: '#53B146' } }
                }}
              />
              <TextField
                label="Username"
                name="username"
                value={credentialForm.username}
                onChange={handleCredentialChange}
                fullWidth
                variant="outlined"
                size="small"
                InputProps={{
                  sx: { '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#53B146' } }
                }}
                InputLabelProps={{
                  sx: { '&.Mui-focused': { color: '#53B146' } }
                }}
              />
              <TextField
                label="Password"
                name="password"
                type="password"
                value={credentialForm.password}
                onChange={handleCredentialChange}
                fullWidth
                variant="outlined"
                size="small"
                InputProps={{
                  sx: { '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#53B146' } }
                }}
                InputLabelProps={{
                  sx: { '&.Mui-focused': { color: '#53B146' } }
                }}
              />
              <TextField
                label="URL"
                name="url"
                value={credentialForm.url}
                onChange={handleCredentialChange}
                fullWidth
                variant="outlined"
                size="small"
                InputProps={{
                  sx: { '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#53B146' } }
                }}
                InputLabelProps={{
                  sx: { '&.Mui-focused': { color: '#53B146' } }
                }}
              />
            </Stack>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button 
                variant="outlined" 
                onClick={closeCredentialModal}
                sx={{ borderColor: '#53B146', color: '#53B146', '&:hover': { borderColor: '#45a139' } }}
              >
                Cancel
              </Button>
              <Button 
                variant="contained" 
                onClick={saveCredentials}
                sx={{ bgcolor: '#53B146', '&:hover': { bgcolor: '#45a139' } }}
              >
                Save Credentials
              </Button>
            </Box>
          </Paper>
        </Modal>

        <Snackbar 
          open={snackbar.open} 
          autoHideDuration={6000} 
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert 
            onClose={handleCloseSnackbar} 
            severity={snackbar.severity}
            variant="filled"
            sx={{ 
              ...(snackbar.severity === 'success' && { bgcolor: '#53B146' })
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default AppConnections;