import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ListSubheader } from '@mui/material';

export default function TemporaryDrawer({ menuSelection, permanent, pageName }) {
  const [open, setOpen] = useState(false);
  const [component, setComponent] = useState('Dashboard')
  const navigate = useNavigate();
  let menu = [];
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  let userRole = localStorage.getItem("role").toLocaleLowerCase();
  userRole = userRole.substring(1, userRole.length - 1);
  if (userRole === "employee") {
    menu = [{ 'menu': 'Self Service', 'item': ['My Incidents', 'My Service Requests', 'My HR Cases'] }];
  } else if (userRole === "itil") {
    menu = [{ 'menu': 'Self Service', 'item': ['My Incidents', 'My Service Requests', 'My HR Cases'] }, { 'menu': 'My Work', 'item': ['Incidents', 'Service Requests', 'HR Cases', 'Approvals', 'Tasks', 'Changes'] }];
    //menu = ['Dashboard', 'Incidents', 'Service Requests', 'HR Cases', 'Tasks'];
  } else if (userRole === "super_admin") {
    menu = [{ 'menu': 'Self Service', 'item': ['My Incidents', 'My Service Requests', 'My HR Cases'] }, { 'menu': 'My Work', 'item': ['Incidents', 'Service Requests', 'HR Cases', 'Approvals', 'Tasks', 'Changes'] }];
    //menu = ['Dashboard', 'Incidents', 'Service Requests', 'HR Cases', 'Tasks', 'Users Management'];
  } else if (userRole === "approver") {
    menu = [{ 'menu': 'Self Service', 'item': ['My Incidents', 'My Service Requests', 'My HR Cases'] }, { 'menu': 'My Work', 'item': ['Approvals'] }];
    //menu = ['Dashboard', 'My Incidents', 'My Service Requests', 'My HR Cases', 'My Approvals'];
  }

  const navigateToPage1 = (page) => () => {
    console.log("opened page " + page);
    setComponent(page);
    if (page === "My Incidents" || page === "Incidents") {
      navigate("/my-incidents");
    } else if (page === "My HR Cases" || page === "HR Cases") {
      navigate("/my-cases");
    } else if (page === "My Service Requests" || page === "Service Requests") {
      navigate("/my-sr");
    } else if (page === "My Tasks" || page === "Tasks") {
      navigate("/my-tasks");
    }
    else if (page === "My Approvals" || page === "Approvals") {
      navigate("/my-approvals");
    } else if (page === "Dashboard") {
      navigate("/home");
    } else if (page === "Users Management") {
      navigate("/users-management");
    }
  };

  const navigateToPage = (menu, page) => () => {
    console.log("opened page " + page);
    setComponent(page);
    if (page === "Dashboard") {
      navigate("/home?main=true")
    } else {
      /*if (pageName === 'home') {
        if (menu === 'Self Service') {
          if (page === "My Incidents" || page === "Incidents") {
            menuSelection({ type: 'incident', section: 'self-service' })
            //navigate("/my-incidents");
          } else if (page === "My HR Cases" || page === "HR Cases") {
            navigate("/my-cases");
          } else if (page === "My Service Requests" || page === "Service Requests") {
            menuSelection({ type: 'request', section: 'self-service' })
            //navigate("/my-sr");
          }
        } else if (menu === 'My Work') {
          if (page === "My Incidents" || page === "Incidents") {
            menuSelection({ type: 'incident', section: 'my-work' })
            //navigate("/my-incidents");
          } else if (page === "My HR Cases" || page === "HR Cases") {
            navigate("/my-cases");
          } else if (page === "My Service Requests" || page === "Service Requests") {
            menuSelection({ type: 'request', section: 'my-work' })
            //navigate("/my-sr");
          } else if (page === "My Tasks" || page === "Tasks") {
            navigate("/my-tasks");
          } else if (page === "My Approvals" || page === "Approvals") {
            navigate("/my-approvals");
          }
        }
      } else {*/
        if (menu === 'Self Service') {
          if (page === "My Incidents" || page === "Incidents") {
            menuSelection({ type: 'incident', section: 'self-service' })
            navigate(`/home?type=incident&section=self-service`);
          } else if (page === "My HR Cases" || page === "HR Cases") {
            navigate("/my-cases");
          } else if (page === "My Service Requests" || page === "Service Requests") {
            menuSelection({ type: 'request', section: 'self-service' })
            navigate(`/home?type=request&section=self-service`);
          }
        } else if (menu === 'My Work') {
          if (page === "My Incidents" || page === "Incidents") {
            navigate(`/home?type=incident&section=my-work`);
          } else if (page === "My HR Cases" || page === "HR Cases") {
            navigate("/my-cases");
          } else if (page === "My Service Requests" || page === "Service Requests") {
            menuSelection({ type: 'request', section: 'my-work' })
            navigate(`/home?type=request&section=my-work`);
          } else if (page === "My Tasks" || page === "Tasks") {
            navigate("/my-tasks");
          } else if (page === "My Approvals" || page === "Approvals") {
            navigate("/my-approvals");
          }
        }
      /*}*/
    }
    
  };

  const DrawerList = (
    <Box sx={{ width: '100%', marginTop: '30%' }} role="presentation" onClick={toggleDrawer(false)}>
      <List >
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary="Home" onClick={navigateToPage(null, 'Dashboard')} />
          </ListItemButton>
        </ListItem>
        {
        /*<ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary="Users Management" onClick={navigateToPage('Users Management')} />
          </ListItemButton>
        </ListItem>
        */}
      </List>
      <Divider />
      {menu.map((text, index) => (
        (text.menu !== 'null') && <List aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {text.menu}
            </ListSubheader>
          }>
          {text.item.map((txt, index) => (<ListItem key={txt} disablePadding>
            <ListItemButton>
              <ListItemText primary={txt} onClick={navigateToPage(text.menu, txt)} />
            </ListItemButton>
          </ListItem>))}
          <Divider />
        </List>
      ))}
      {[{ 'menu': 'Company', 'item': ['Events', 'Announcements', 'News'] }, { 'menu': 'Knowledge', 'item': ['Knowledge Article', 'FAQs'] }].map((text, index) => (
        (text.menu !== 'null') && <List aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {text.menu}
            </ListSubheader>
          }>
          {text.item.map((txt, index) => (<ListItem key={txt} disablePadding>
            <ListItemButton>
              <ListItemText primary={txt} onClick={navigateToPage(text.menu, txt)} />
            </ListItemButton>
          </ListItem>))}
          <Divider />
        </List>
      ))}
    </Box>
  );

  return (
    <div>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="open drawer"
        sx={{ mr: 2, color: '#53B146' }} onClick={toggleDrawer(!open)}
      >
        <MenuIcon />
      </IconButton>
      {permanent && <Drawer open={open} variant='permanent' onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          },
        }}>
        {DrawerList}
      </Drawer>}
      {!permanent && <Drawer open={open} onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          },
        }}>
        {DrawerList}
      </Drawer>}
    </div>
  );
}
