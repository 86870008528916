import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import TemporaryDrawer from './DrawerList';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../hooks/useLocalStorage';
import bannerImg from '../assets/search_banner2.jpg';
import {
    CssBaseline,
    Divider,
    Grid2,
    Link,
    List,
    ListItem,
    ListItemText,
    Skeleton,
    ScopedCssBaseline,
    Paper,
    Card,
    CardContent,
    ListItemIcon,
    CircularProgress,
    ToggleButton,
    Tooltip,
    Collapse
} from '@mui/material';
import {
    AccountCircleOutlined,
    AccountCircleRounded,
    Logout,
    LogoutOutlined,
    NoEncryption,
    NotificationsOutlined,
    SettingsOutlined,
    Circle,
    Business,
    Language,
    Link as LinkIcon,
    ExpandLess,
    ExpandMore
} from '@mui/icons-material';
import axios from "axios";
import axiosInstance from './../axios';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'inherit',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme, disabled }) => ({
    color: disabled ? 'rgba(0, 0, 0, 0.38)' : 'inherit',
    width: 'inherit',
    border: 'solid',
    borderColor: disabled ? '#e0e0e0' : '#cccccc',
    borderWidth: 'medium',
    borderRadius: '5px',
    opacity: disabled ? 0.7 : 1,
    '& .MuiInputBase-input': {
        padding: theme.spacing(2, 2, 2, 1),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%'
    },
}));

// Component to display the conversation thread
const ConversationThread = ({ conversations, loading, currentQuery }) => {
    const containerRef = React.useRef(null);

    // Function to scroll to bottom
    const scrollToBottom = () => {
        if (containerRef.current) {
            const scrollContainer = document.getElementById('results-container');
            if (scrollContainer) {
                scrollContainer.scrollTop = scrollContainer.scrollHeight;
            }
        }
    };

    // Scroll to bottom on every render
    React.useEffect(() => {
        scrollToBottom();
    });

    // Also scroll when conversations or loading state change
    React.useEffect(() => {
        scrollToBottom();
        // Set a timeout to scroll again after content has been rendered
        const timeoutId = setTimeout(scrollToBottom, 100);
        return () => clearTimeout(timeoutId);
    }, [conversations, loading]);

    if (conversations.length === 0 && !loading) {
        return (
            <Typography variant="body1" color="text.secondary" sx={{ mt: 2, textAlign: 'center' }}>
                Enter a search query and press Enter to see results.
            </Typography>
        );
    }

    return (
        <Box sx={{ width: '100%' }} ref={containerRef}>
            {conversations.map((convo, index) => (
                <FormattedSearchResult
                    key={index}
                    responseText={convo.answer}
                    searchQuery={convo.question}
                    isError={convo.isError}
                    isLatest={index === conversations.length - 1}
                    onTypingProgress={scrollToBottom}
                    sources={convo.sources || []}
                />
            ))}

            {loading && (
                <>
                    {currentQuery && (
                        <Card variant="outlined" sx={{ width: '100%', marginTop: 2, marginBottom: 0, boxShadow: 'none', border: 'none' }}>
                            <CardContent sx={{ padding: 0 }}>
                                <Box sx={{ padding: '16px 16px 8px 16px' }}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: 500,
                                            color: 'rgba(0, 0, 0, 0.87)',
                                            wordBreak: 'break-word'
                                        }}
                                    >
                                        {currentQuery}
                                    </Typography>
                                </Box>
                                <Divider sx={{ marginBottom: '16px' }} />
                            </CardContent>
                        </Card>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100px', mt: 2 }}>
                        <CircularProgress color="primary" />
                    </Box>
                </>
            )}
        </Box>
    );
};

// Component to format and render API response text with Typed.js animation
// Updated FormattedSearchResult Component
const FormattedSearchResult = ({ responseText, searchQuery, isError = false, isLatest = false, onTypingProgress, sources = [] }) => {
    const typedRef = React.useRef(null);
    const typedInstanceRef = React.useRef(null);
    const [hasTyped, setHasTyped] = React.useState(false);
    const [showSources, setShowSources] = React.useState(false);
    const [orgSourcesExpanded, setOrgSourcesExpanded] = React.useState(false);
    const [webSourcesExpanded, setWebSourcesExpanded] = React.useState(false);

    React.useEffect(() => {
        // If this is not the latest message or has already been typed, render without animation
        if (!isLatest || hasTyped) {
            if (typedRef.current) {
                typedRef.current.innerHTML = formatResponseToHtml(responseText);
            }
            return;
        }

        // Import Typed.js dynamically for latest message
        const loadTyped = async () => {
            try {
                // We're using a CDN as mentioned in the instructions
                if (!window.Typed) {
                    // Create script element for Typed.js
                    const script = document.createElement('script');
                    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/typed.js/2.0.12/typed.min.js';
                    script.async = true;

                    // Wait for script to load
                    await new Promise((resolve, reject) => {
                        script.onload = resolve;
                        script.onerror = reject;
                        document.head.appendChild(script);
                    });
                }

                // Only initialize if we have text and the element exists
                if (responseText && typedRef.current) {
                    // Clean up previous instance if it exists
                    if (typedInstanceRef.current) {
                        typedInstanceRef.current.destroy();
                    }

                    // Get the formatted HTML
                    const formattedHtml = formatResponseToHtml(responseText);

                    // Set up MutationObserver to watch for changes
                    const observer = new MutationObserver(() => {
                        // Scroll the container to the bottom when content changes
                        const scrollContainer = document.getElementById('results-container');
                        if (scrollContainer) {
                            scrollContainer.scrollTop = scrollContainer.scrollHeight;
                        }
                    });

                    // Options for MutationObserver to observe child elements and text changes
                    const config = {
                        childList: true,    // Observe additions/removals of child elements
                        subtree: true,      // Observe the entire subtree
                        characterData: true, // Observe changes to text content
                    };

                    // Start observing the div element
                    if (typedRef.current) {
                        observer.observe(typedRef.current, config);
                    }

                    // Initialize Typed.js
                    typedInstanceRef.current = new window.Typed(typedRef.current, {
                        strings: [formattedHtml],
                        typeSpeed: 10, // Match the speed in the reference component
                        showCursor: false,
                        loop: false,
                        smartBackspace: true,
                        contentType: 'html',
                        onStringTyped: function () {
                            console.log("done typing");
                        },
                        onComplete: function (self) {
                            // Mark this message as having been typed
                            setHasTyped(true);

                            // Now show the sources since typing is complete
                            setShowSources(true);

                            // Ensure full content is shown
                            if (typedRef.current && self.el) {
                                // Force innerHTML to be the complete string in case of parsing issues
                                typedRef.current.innerHTML = formattedHtml;
                            }

                            // Final scroll to ensure we're at the bottom
                            setTimeout(() => {
                                const scrollContainer = document.getElementById('results-container');
                                if (scrollContainer) {
                                    scrollContainer.scrollTop = scrollContainer.scrollHeight;
                                }
                            }, 100);

                            // Focus on the search input when typing is complete
                            const searchInput = document.getElementById('searchbar');
                            if (searchInput) {
                                searchInput.focus();
                            }

                            // Disconnect the observer
                            observer.disconnect();
                        }
                    });

                    // Return a cleanup function
                    return () => {
                        if (typedInstanceRef.current) {
                            typedInstanceRef.current.destroy();
                        }
                        observer.disconnect();
                    };
                }
            } catch (error) {
                console.error('Error loading or initializing Typed.js:', error);
                // Fallback in case Typed.js fails to load
                if (typedRef.current) {
                    typedRef.current.innerHTML = formatResponseToHtml(responseText);
                    setHasTyped(true);
                    setShowSources(true);
                }
            }
        };

        loadTyped();

        // Cleanup function
        return () => {
            if (typedInstanceRef.current) {
                typedInstanceRef.current.destroy();
            }
        };
    }, [responseText, isLatest, onTypingProgress, hasTyped]);

    // Format the response text to proper HTML
    const formatResponseToHtml = (text) => {
        if (!text) return '';

        // Replace ** bold markers with actual bold text
        let formattedText = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

        // Replace links with icon links
        formattedText = formattedText.replace(/\[([^\]]+)\]\(([^)]+)\)/g,
            '<span class="link-container"><a href="$2" target="_blank" rel="noreferrer" style="color: #1976d2; text-decoration: none; display: inline-flex; align-items: center;"><span style="margin-right: 4px;">$1</span><span class="link-icon" style="display: inline-flex; align-items: center;"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg></span></a></span>');

        // Preserve newlines by replacing them with <br> tags
        formattedText = formattedText.replace(/\n/g, '<br>');

        return formattedText;
    };

    // Separate sources into internal and external
    const internalSources = sources ? sources.filter(source => source.internal) : [];
    const externalSources = sources ? sources.filter(source => !source.internal) : [];

    return (
        <Card variant="outlined" sx={{
            width: '100%',
            marginTop: 2,
            marginBottom: 2,
            boxShadow: 'none',
            border: 'none',
            backgroundColor: isError ? 'rgba(244, 67, 54, 0.05)' : 'transparent'
        }}>
            <CardContent sx={{ padding: 0 }}>
                {/* Search query header */}
                <Box sx={{ padding: '16px 16px 8px 16px' }}>
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 500,
                            color: 'rgba(0, 0, 0, 0.87)',
                            wordBreak: 'break-word'
                        }}
                    >
                        {searchQuery || 'Search results'}
                    </Typography>
                </Box>

                <Divider sx={{ marginBottom: '16px' }} />

                <Box sx={{ padding: '0 16px 16px 16px' }}>
                    <div
                        ref={typedRef}
                        style={{
                            minHeight: '50px',
                            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                            fontSize: '1rem',
                            lineHeight: 1.5,
                            color: isError ? '#f44336' : 'rgba(0, 0, 0, 0.87)',
                        }}
                    ></div>

                    {/* Display sources only if there are any AND typing is complete */}
                    {showSources && (internalSources.length > 0 || externalSources.length > 0) && (
                        <Box sx={{ mt: 3 }}>
                            <Divider sx={{ mb: 2 }} />
                            <Typography variant="h6" sx={{ mb: 1, fontSize: '1rem', fontWeight: 500 }}>
                                Sources
                            </Typography>

                            {/* Organization Sources */}
                            {internalSources.length > 0 && (
                                <Box sx={{ mb: 2 }}>
                                    <Box
                                        onClick={() => setOrgSourcesExpanded(!orgSourcesExpanded)}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            cursor: 'pointer',
                                            mb: 1,
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                            },
                                            borderRadius: '4px',
                                            padding: '4px 8px'
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontSize: '0.875rem',
                                                fontWeight: 500,
                                                color: 'rgba(0, 0, 0, 0.6)',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Business sx={{ fontSize: 18, mr: 0.5 }} />
                                            Organization ({internalSources.length})
                                        </Typography>
                                        {orgSourcesExpanded ?
                                            <ExpandLess fontSize="small" color="action" /> :
                                            <ExpandMore fontSize="small" color="action" />
                                        }
                                    </Box>
                                    <Collapse in={orgSourcesExpanded} timeout="auto" unmountOnExit>
                                        <List dense disablePadding>
                                            {internalSources.map((source, index) => (
                                                <ListItem
                                                    key={`internal-${index}`}
                                                    disablePadding
                                                    sx={{ py: 0.5 }}
                                                >
                                                    <ListItemIcon sx={{ minWidth: 32 }}>
                                                        <Typography
                                                            variant="body2"
                                                            sx={{ fontWeight: 500, color: 'text.secondary' }}
                                                        >
                                                            {source.number}.
                                                        </Typography>
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Link
                                                                href={source.url}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                sx={{
                                                                    color: '#1976d2',
                                                                    textDecoration: 'none',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    '&:hover': { textDecoration: 'underline' }
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="body2"
                                                                    component="span"
                                                                    sx={{
                                                                        mr: 0.5,
                                                                        maxWidth: '100%',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap'
                                                                    }}
                                                                >
                                                                    {source.url}
                                                                </Typography>
                                                                <LinkIcon fontSize="small" sx={{ fontSize: 14, flexShrink: 0 }} />
                                                            </Link>
                                                        }
                                                        sx={{ m: 0 }}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                </Box>
                            )}

                            {/* Web Sources */}
                            {externalSources.length > 0 && (
                                <Box>
                                    <Box
                                        onClick={() => setWebSourcesExpanded(!webSourcesExpanded)}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            cursor: 'pointer',
                                            mb: 1,
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                            },
                                            borderRadius: '4px',
                                            padding: '4px 8px'
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontSize: '0.875rem',
                                                fontWeight: 500,
                                                color: 'rgba(0, 0, 0, 0.6)',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Language sx={{ fontSize: 18, mr: 0.5 }} />
                                            Web ({externalSources.length})
                                        </Typography>
                                        {webSourcesExpanded ?
                                            <ExpandLess fontSize="small" color="action" /> :
                                            <ExpandMore fontSize="small" color="action" />
                                        }
                                    </Box>
                                    <Collapse in={webSourcesExpanded} timeout="auto" unmountOnExit>
                                        <List dense disablePadding>
                                            {externalSources.map((source, index) => (
                                                <ListItem
                                                    key={`external-${index}`}
                                                    disablePadding
                                                    sx={{ py: 0.5 }}
                                                >
                                                    <ListItemIcon sx={{ minWidth: 32 }}>
                                                        <Typography
                                                            variant="body2"
                                                            sx={{ fontWeight: 500, color: 'text.secondary' }}
                                                        >
                                                            {source.number}.
                                                        </Typography>
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Link
                                                                href={source.url}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                sx={{
                                                                    color: '#1976d2',
                                                                    textDecoration: 'none',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    '&:hover': { textDecoration: 'underline' }
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="body2"
                                                                    component="span"
                                                                    sx={{
                                                                        mr: 0.5,
                                                                        maxWidth: '100%',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap'
                                                                    }}
                                                                >
                                                                    {source.url}
                                                                </Typography>
                                                                <LinkIcon fontSize="small" sx={{ fontSize: 14, flexShrink: 0 }} />
                                                            </Link>
                                                        }
                                                        sx={{ m: 0 }}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};

export default function HomeMain({ menuSelect, pageName }) {
    const [user, setUser] = useLocalStorage('user');
    const [searchString, setSearchString] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [searchResponse, setSearchResponse] = React.useState("");
    const [enableOrgSearch, setEnableOrgSearch] = React.useState(true);
    const [enableWebSearch, setEnableWebSearch] = React.useState(true);
    const [threadId, setThreadId] = React.useState(null);
    const [conversations, setConversations] = React.useState([]);

    // Add global scroll function
    React.useEffect(() => {
        // Add a direct scroll function to window for easier access
        window.scrollResultsToBottom = () => {
            const container = document.getElementById('results-container');
            if (container) {
                container.scrollTop = container.scrollHeight;
            }
        };

        // Set an interval to try scrolling during typing
        const intervalId = setInterval(() => {
            if (loading) {
                window.scrollResultsToBottom();
            }
        }, 100);

        // Cleanup
        return () => {
            clearInterval(intervalId);
            delete window.scrollResultsToBottom;
        };
    }, [loading]);

    console.log("Page name::: ", pageName);

    const handleSearch = async (e) => {
        if (e.keyCode == 13 && !loading) {
            const searchQuery = e.target.value;
            console.log('value', searchQuery);
            setSearchString(searchQuery); // Store the search query
            const token = localStorage.getItem("token");
            setLoading(true); // Set loading to true when search begins

            try {
                let customHeaders = {};
                customHeaders['Authorization'] = 'Bearer ' + token.substring(1, token.length - 1);
                customHeaders['Access-Control-Allow-Origin'] = '*';
                customHeaders['Content-Type'] = 'application/json';

                // TEMPORARY CHANGE: Always use null thread_id instead of the stored threadId
                // Original code:
                // const response = await axiosInstance.post('https://api.kogniv.com/api/v1/es/es-assist', {
                //     "message": searchQuery,
                //     "thread_id": threadId, // Use the stored thread_id for follow-up questions
                //     "enable_search": true,
                //     "enable_es_search": enableOrgSearch,
                //     "enable_web_search": enableWebSearch
                // }, { customHeaders });

                // Temporary implementation: always use null thread_id
                const response = await axiosInstance.post('https://api.kogniv.com/api/v1/es/es-assist', {
                    "message": searchQuery,
                    "thread_id": null, // TEMPORARY: Always use null to treat as new conversation
                    "enable_search": true,
                    "enable_es_search": enableOrgSearch,
                    "enable_web_search": enableWebSearch
                }, { customHeaders });

                if (response.status === 200) {
                    let message = "";

                    // Store the thread_id for future follow-up questions
                    if (response.data.thread_id) {
                        setThreadId(response.data.thread_id);
                        localStorage.setItem('search_conv_id', response.data.thread_id);
                    }

                    if (response.data.response === "") {
                        message = "No results found. Please try a different search query.";
                    } else {
                        message = response.data.response;
                    }

                    // Extract sources from the response if they exist
                    const sources = response.data.sources || [];

                    // Add the new Q&A pair to conversations with sources
                    setConversations(prevConversations => [
                        ...prevConversations,
                        {
                            question: searchQuery,
                            answer: message,
                            sources: sources,
                            timestamp: new Date().toISOString()
                        }
                    ]);

                    setSearchResponse(message);
                    // Clear the search input for the next question
                    if (document.getElementById('searchbar')) {
                        document.getElementById('searchbar').value = '';
                    }
                    console.log("Output::: ", message);
                    console.log("Sources::: ", sources);

                    // Force scroll to bottom
                    setTimeout(() => {
                        window.scrollResultsToBottom();
                    }, 100);
                }
            } catch (err) {
                console.log("Error in API response::: ", err);
                setConversations(prevConversations => [
                    ...prevConversations,
                    {
                        question: searchQuery,
                        answer: "An error occurred while fetching search results. Please try again later.",
                        sources: [],
                        timestamp: new Date().toISOString(),
                        isError: true
                    }
                ]);
                setSearchResponse("An error occurred while fetching search results. Please try again later.");

                // Force scroll to bottom
                setTimeout(() => {
                    window.scrollResultsToBottom();
                }, 100);
            } finally {
                setLoading(false); // Set loading to false when search completes (success or error)
            }
        }
    };

    /*
    // Old search function (commented out as requested)
    const handleSearchOld = async (e) => {
        if (e.keyCode == 13) {
            console.log('value', e.target.value);
            const token = localStorage.getItem("token");
            setLoading(true); // Set loading to true when search begins
            
            try {
                axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
                axios.defaults.headers.get['Content-Type'] = 'application/json';
                axios.defaults.headers.get["x-rapidapi-key"] = "a2ab3c2463msh53fa8f30332d824p1fa7dfjsne69a9230e8c8";
                axios.defaults.headers.get["x-rapidapi-host"] = "real-time-web-search.p.rapidapi.com";
                let response = await axios.get(`https://real-time-web-search.p.rapidapi.com/search?q=${e.target.value}&limit=10`);
                if (response.status === 200) {
                    setTrendingSearch(response.data.data);
                }
            } catch (err) {
                console.log("Error in API response::: ", err)
                setTrendingSearch([{title:"Error in fetching the response, try again!"}]);
            } finally {
                setLoading(false); // Set loading to false when search completes (success or error)
            }
        }
    }
    */

    return (
        <Box sx={{ flexGrow: 1 }}>
            <ScopedCssBaseline />
            <Box sx={{ minWidth: '100%', minHeight: 'calc(100vh - 100px)' }}>
                <Grid2 display='flex' container alignItems='center' sx={{
                    backgroundImage: `url(${bannerImg})`,
                    backgroundSize: 'cover',
                    height: '200px',
                    minWidth: '100%',
                    width: '100%',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1100,
                    borderBottom: '1px solid #e0e0e0'
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: 'inherit',
                        padding: '0 16px'
                    }}>
                        <Search sx={{
                            marginTop: '0.5em',
                            marginBottom: '0.5em',
                            width: 'inherit',
                            flex: 1
                        }}>
                            <SearchIconWrapper>
                                <SearchIcon sx={{ color: '#53B146' }} />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                id="searchbar"
                                disabled={loading}
                                inputProps={{ 'aria-label': 'search' }}
                                onKeyDown={(e) => {
                                    if (!loading) {
                                        handleSearch(e);
                                    }
                                }}
                            />
                        </Search>
                        <Box sx={{ marginLeft: '10px', display: 'flex' }}>
                            <Tooltip title="Organization Search">
                                <IconButton
                                    disabled={loading}
                                    color={enableOrgSearch ? "primary" : "default"}
                                    onClick={() => !loading && setEnableOrgSearch(!enableOrgSearch)}
                                    sx={{
                                        backgroundColor: enableOrgSearch ? 'rgba(25, 118, 210, 0.08)' : 'transparent',
                                        marginRight: '8px',
                                        '&:hover': {
                                            backgroundColor: !loading && (enableOrgSearch ? 'rgba(25, 118, 210, 0.16)' : 'rgba(0, 0, 0, 0.04)')
                                        }
                                    }}
                                >
                                    <Business />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Web Search">
                                <IconButton
                                    disabled={loading}
                                    color={enableWebSearch ? "primary" : "default"}
                                    onClick={() => !loading && setEnableWebSearch(!enableWebSearch)}
                                    sx={{
                                        backgroundColor: enableWebSearch ? 'rgba(25, 118, 210, 0.08)' : 'transparent',
                                        '&:hover': {
                                            backgroundColor: !loading && (enableWebSearch ? 'rgba(25, 118, 210, 0.16)' : 'rgba(0, 0, 0, 0.04)')
                                        }
                                    }}
                                >
                                    <Language />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Grid2>

                <Grid2 display='flex' container sx={{ minWidth: '100%', width: '100%' }}>
                    <Box
                        id="results-container"
                        sx={{
                            width: '100%',
                            padding: '16px',
                            maxHeight: 'calc(90vh - 200px)', // 90% of viewport height minus search bar height
                            overflow: 'auto', // Add scrollbar when content exceeds max height
                            msOverflowStyle: 'none', // Hide scrollbar in IE and Edge
                            scrollbarWidth: 'none', // Hide scrollbar in Firefox
                            '&::-webkit-scrollbar': { // Hide scrollbar in Chrome, Safari and Opera
                                display: 'none'
                            }
                        }}
                    >
                        <ConversationThread
                            conversations={conversations}
                            loading={loading}
                            currentQuery={loading ? searchString : ""}
                        />
                    </Box>
                </Grid2>

                {/* Commented out the old search results section as requested
                <Grid2 display='flex' container sx={{ minWidth: '100%', width: '100%' }}>
                    <List sx={{ width: '100%', margin: '5px', padding: '5px', bgcolor: 'background.paper' }}>
                        {loading ? (
                            <SearchResultSkeleton />
                        ) : (
                            trendingSearch.map((task, index) => (
                                <ListItem alignItems="flex-start" sx={{ margin: '5px' }} key={index}>
                                    <ListItemText
                                        primary={<React.Fragment>
                                            <Grid2 container spacing={1}>
                                                <Grid2 size={12}>
                                                    <Typography
                                                        component="span"
                                                        variant="body2"
                                                        sx={{ color: 'text.primary', display: 'inline', fontWeight: 500 }}
                                                    >
                                                        {task.title}
                                                    </Typography>
                                                </Grid2>
                                            </Grid2>
                                        </React.Fragment>}
                                        secondary={
                                            <React.Fragment>
                                                {task.snippet !== undefined && task.snippet !== "" && <Typography
                                                    component="span"
                                                    variant="body2"
                                                    sx={{ color: 'text.primary', display: 'inline' }}>
                                                    {task.snippet}
                                                </Typography>}
                                                <br />
                                                {task.url !== undefined && task.url !== "" && <a target="_blank" rel="noreferrer" href={task.url} style={{color:"inherit", wordBreak:'break-word'}} >{task.url}</a>}
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            ))
                        )}
                    </List>
                </Grid2>
                */}
            </Box>
        </Box>
    );
}