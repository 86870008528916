import { OpenInNew } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Card, CardContent, Typography } from '@mui/material';
import React, { useState } from 'react';
import parse from 'html-react-parser';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

const ResourceCardMsg = (props) => {
    const [open, setOpen] = useState(false);
    let parsedContent = null;
    if (props.kb) {
        parsedContent = parse(props.kb.text);
    }
    let botName = localStorage.getItem("botName");
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAttach = () => {
        props.attach(props.kb.number);
        handleClose()
        //alert("attaching in progress")
    };

    // Function to format date
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleString();
    };

    const cardContainerStyle = {
        display: "block", 
        margin: "6px 0", 
        backgroundColor: "white",
    };

    if (props.type === 'chat') {
        cardContainerStyle.border = '1px solid #e0e0e0'
        cardContainerStyle.padding = '10px 15px'
        cardContainerStyle.borderRadius = '4px'
    } 

    const cardContentStyle = {
        cursor: 'pointer'
    };

    const incidentNumberStyle = {
        fontSize: "12px", // Smaller font for incident number
        fontWeight: "500",
        color: "#666",
        marginBottom: "2px"
    };

    const dateStyle = {
        fontSize: "11px",
        color: "text.secondary",
        marginTop: "2px"
    };

    const titleStyle = {
        fontSize: "13px",
        marginTop: "2px",
        lineHeight: "1.3"
    };

    return (
        <div style={cardContainerStyle}>
            {props.kb && (
                <div
                    onClick={handleClickOpen}
                    style={cardContentStyle}
                >
                    <Typography variant="subtitle2" component="div" style={incidentNumberStyle}>
                        {props.kb.number}
                    </Typography>
                    <Typography variant="body2" style={dateStyle}>
                        {props.kb.published ? formatDate(props.kb.published) : 'Not published'}
                    </Typography>
                    <Typography variant="body1" style={titleStyle}>
                        {props.kb.short_description}
                    </Typography>
                </div>
            )}

            {/* Dialog for KB details - unchanged */}
            {props.kb && (
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title">
                        {props.kb.number} - {props.kb.short_description}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                Published: {props.kb.published ? formatDate(props.kb.published) : 'Not published'}
                            </Typography>
                            <div className="kb-content">
                                {parsedContent}
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {botName === 'knowledgeAssist' && props.attach && <Button onClick={handleAttach}>Attach</Button>}
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            )}

            {props.inc && (
                <div
                    onClick={handleClickOpen}
                    style={cardContentStyle}
                >
                    <Typography variant="subtitle2" component="div" style={incidentNumberStyle}>
                        {props.inc.number}
                    </Typography>
                    {/*<Typography variant="body2" style={dateStyle}>
                        {formatDate(props.inc.sys_created_on)}
                    </Typography>*/}
                    <Typography variant="body1" style={titleStyle}>
                        {props.inc.short_description}
                    </Typography>
                </div>
            )}

            {/* Dialog for incident details - unchanged */}
            {props.inc && (
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title">
                        {props.inc.number} - {props.inc.short_description}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                Created: {formatDate(props.inc.sys_created_on)}
                            </Typography>
                            <Typography variant="h6" sx={{ mt: 2 }}>Description</Typography>
                            <Typography paragraph>
                                {props.inc.description || "No description available"}
                            </Typography>

                            <Typography variant="h6" sx={{ mt: 2 }}>Resolution Notes</Typography>
                            <Typography paragraph>
                                {props.inc.close_notes || "No resolution notes available"}
                            </Typography>

                            {props.inc.workNotes && props.inc.workNotes.length > 0 && (
                                <>
                                    <Typography variant="h6" sx={{ mt: 2 }}>Work Notes</Typography>
                                    <Timeline
                                        sx={{
                                            [`& .${timelineOppositeContentClasses.root}`]: {
                                                flex: 0.2,
                                            },
                                        }}
                                    >
                                        {props.inc.workNotes.map((note, index) => (
                                            <TimelineItem key={index}>
                                                <TimelineOppositeContent color="textSecondary">
                                                    {note.date}
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineDot />
                                                    {index < props.inc.workNotes.length - 1 && <TimelineConnector />}
                                                </TimelineSeparator>
                                                <TimelineContent>{note.text}</TimelineContent>
                                            </TimelineItem>
                                        ))}
                                    </Timeline>
                                </>
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};

export default ResourceCardMsg;