import React from "react";

import "./Options.css";

const Options = (props) => {
  console.log("props in options widget::", props)
  let botName = localStorage.getItem("botName");
  
  let options = [
    { text: "I need more help", handler: props.actionProvider.handleSelectResponse, id: 1 },
    { text: "It was not helpful", handler: props.actionProvider.handleSelectResponse, id: 3 }
  ];
  if (botName && botName === 'knowledgeAssist') {
    options.push(
      { text: "Related incidents", handler: props.actionProvider.handleSelectResponse, id: 4 })
  }

  const buttonsMarkup = options.map((option) => (
    <button key={option.id} onClick={option.handler} className="option-button">
      {option.text}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};

export default Options;
