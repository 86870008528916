import React from "react";

const MessageParser = ({ children, actions }) => {

  const parse = async (message) => {
    console.log("parse>>>>>> ", message);
    const lowercase = message.toLowerCase();
    if (lowercase !== '') {
      if (lowercase.includes("hello") || lowercase === `hi`) {
        actions.greet();
      } else {
        //flow to fetch service catalog
        //actions.callServiceCatalogAPI(lowercase)
        actions.callChatBotAPI(lowercase)
      }
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions
        });
      })}
    </div>
  );
};

export default MessageParser;
