import * as React from 'react';
import axios from "axios";
import { useEffect, useState } from "react";
import {
  Box,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Typography,
  Skeleton,
  Chip,
  Pagination,
  Paper,
  InputAdornment,
  Card,
  CardContent,
  Divider,
  Alert,
  Snackbar
} from "@mui/material";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";
import SNowListingTable from './SNowListingTable';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator
} from '@mui/lab';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

const hostName = 'https://kogniv-iservice-gvgmhpeuf5eaaghu.centralus-01.azurewebsites.net/api/main';
//const hostName = 'http://localhost:8000/api/main'

const SNowListing = ({ taskType, section }) => {
  const navigate = useNavigate();

  const [taskList, setTaskList] = useState([]);
  const [filteredTaskList, setFilteredTaskList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [visible, setVisible] = useState(false);
  const [inc, setInc] = useState(null);
  const [sSInc, setSSInc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [taskTypeProp, setTaskTypeProp] = useState("");
  const [sectionProp, setSectionProp] = useState("");
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('success');
  const [newIncImpact, setNewIncImpact] = useState('');
  const [newIncUrgency, setNewIncUrgency] = useState('');
  const [newState, setNewState] = useState('');
  const [resolutionCode, setResolutionCode] = useState('');
  const [newKnowledge, setNewKnowledge] = useState(false);
  const [shortDescForBot, setShortDescForBot] = useState(null);
  const [openNewInc, setOpenNewInc] = useState(false);
  const [openSSInc, setOpenSSInc] = useState(false);
  const [sSIncImpact, setSSIncImpact] = useState('');
  const [sSIncUrgency, setSSIncUrgency] = useState('');
  const [sSIncState, setSSIncState] = useState('');

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert(false);
  };

  const handleNewSelectChange = (event) => {
    setNewIncImpact(event.target.value)
  };

  const handleNewSelectChangeUrg = (event) => {
    setNewIncUrgency(event.target.value)
  };

  const handleSelectChange = (event) => {
    let tempSSInc = sSInc;
    tempSSInc.impact = event.target.value;
    setSSIncImpact(event.target.value)
    setSSInc(tempSSInc);
  };

  const handleSelectChangeUrg = (event) => {
    let tempSSInc = sSInc;
    tempSSInc.urgency = event.target.value;
    setSSIncUrgency(event.target.value);
    setSSInc(tempSSInc);
  };

  const handleSelectChangeState = (event) => {
    let tempSSInc = sSInc;
    tempSSInc.state = event.target.value;
    setSSIncState(event.target.value);
    setSSInc(tempSSInc);
  };

  const handleSelectChangeIncState = (event) => {
    setNewState(event.target.value);
  };

  const handleSelectChangeIncResolutionCode = (event) => {
    setResolutionCode(event.target.value);
  };

  const handleKnowledgeToggle = (event) => {
    setNewKnowledge(event.target.checked)
  }

  const handleNewIncOpen = () => {
    setOpenNewInc(true);
    setNewIncImpact('');
    setNewIncUrgency('');
  };

  const handleNewIncClose = () => {
    setOpenNewInc(false);
    setNewIncImpact('');
    setNewIncUrgency('');
  };

  const handleSSIncOpen = (inc) => {
    // Process work notes from comments_and_work_notes when an incident is clicked
    const formattedInc = { ...inc };

    if (formattedInc.comments_and_work_notes) {
      formattedInc.comments_and_work_notes = formattedInc.comments_and_work_notes.replace('\n\n', '\n');
      let tempworkNotes = formattedInc.comments_and_work_notes.split('\n');
      let workNotes = [];
      let note = {};

      tempworkNotes = tempworkNotes.filter(e => e !== '');

      for (const [i, row] of tempworkNotes.entries()) {
        if (i % 2 === 0) {
          note = {};
          note.date = row;
        } else {
          note.text = row;
          workNotes.push(note);
        }
      }

      formattedInc.workNotes = workNotes;
    } else {
      formattedInc.workNotes = [];
    }

    setSSInc(formattedInc);
    setSSIncImpact(formattedInc.impact);
    setSSIncUrgency(formattedInc.urgency);
    setSSIncState(formattedInc.state);
    setOpenSSInc(true);
  };

  const handleSSIncClose = () => {
    setOpenSSInc(false);
  };

  // Sort and filter tasks
  useEffect(() => {
    if (taskList.length > 0) {
      // Sort tasks by creation date (newest first)
      const sortedTasks = [...taskList].sort((a, b) => {
        const dateA = new Date(a.sys_created_on);
        const dateB = new Date(b.sys_created_on);
        return dateB - dateA;
      });

      // Apply search filter if query exists
      if (searchQuery) {
        const filtered = sortedTasks.filter(task =>
          (task.number && task.number.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (task.short_description && task.short_description.toLowerCase().includes(searchQuery.toLowerCase()))
        );
        setFilteredTaskList(filtered);
      } else {
        setFilteredTaskList(sortedTasks);
      }
    } else {
      setFilteredTaskList([]);
    }
    // Reset to first page when search changes
    setCurrentPage(1);
  }, [taskList, searchQuery]);

  let userRole = localStorage.getItem("role").toLocaleLowerCase();
  userRole = userRole.substring(1, userRole.length - 1);
  let userID = localStorage.getItem("userID").toLocaleLowerCase();
  userID = userID.substring(1, userID.length - 1);
  console.log("typeof userid::::::::::::", userID)

  const getTasksList = async () => {
    try {
      setLoading(true);
      let data = {
        "appID": 1,
        "params": {
          "data": null,
          "methodType": "get",
          "query": {}
        }
      }
      let query = {
        sysparm_display_value: "true"
      }
      if (section === 'self-service') {
        if (taskType === 'incident') {
          data.params.entity = 'incident'
          query.sysparm_query = "active=true^caller_id=" + userID
          query.sysparm_fields = "number,state,sys_id,sys_created_on,comments_and_work_notes,priority,short_description,due_date,caller_id,description,impact,urgency"
        } else if (taskType === 'request') {
          data.params.entity = 'list_sr'
          query.sysparm_query = "requested_for=" + userID + "^active=true"
          query.sysparm_fields = "number,request_state,sys_id,sys_created_on,comments_and_work_notes,priority,short_description,due_date,approval,requested_for"
        }
      }
      data.params.query = query
      const token = localStorage.getItem("token");
      let user = localStorage.getItem("user").toLocaleLowerCase();
      user = user.substring(1, user.length - 1)
      axios.defaults.headers.post['x-api-key'] = `test`;
      axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.post['Content-Type'] = 'application/json';

      axios.post(`${hostName}`, data).then((res) => {
        setLoading(false)
        if (res.status === 200) {
          if (res.data && res.data.data && res.data.data.result) {
            // Format date for better display
            const formattedTasks = res.data.data.result.map(item => ({
              ...item,
              sys_created_on: formatDate(item.sys_created_on)
            }));
            setTaskList(formattedTasks);
          }
        } else {
          console.log(res.status);
          setLoading(false);
        }
      })
    }
    catch (error) {
      setLoading(false)
      console.log("error while fetching inc list:::", error)
    }
  };

  // Format date for better readability
  const formatDate = (dateString) => {
    if (!dateString) return '';
    return moment(dateString).format('MMM DD, YYYY');
  };

  // Get priority color based on value
  const getPriorityColor = (priority) => {
    if (!priority) return 'info';
    if (priority.includes('Critical') || priority.includes('1')) return 'error';
    if (priority.includes('Moderate') || priority.includes('2')) return 'warning';
    return 'info';
  };

  // Get status color based on value
  const getStatusColor = (status) => {
    if (!status) return 'default';
    if (status === 'New') return 'info';
    if (status === 'In Progress') return 'info';
    if (status === 'Resolved' || status === 'Approved') return 'success';
    if (status === 'Pending Approval') return 'warning';
    return 'default';
  };

  useEffect(() => {
    setTaskTypeProp(taskType);
    setSectionProp(section);
    setTaskList([]);
    setSearchQuery('');
    setCurrentPage(1);
    getTasksList();
  }, [taskType, section]);

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handle pagination change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleRowClick = async (params) => {
    console.log("row click called", params)
    if (taskTypeProp === 'incident') {
      navigate(`/my-incidents?num=${params}`)
    } else if (taskTypeProp === 'incident') {
      navigate(`/my-sr?num=${params}`)
    }
  };

  const handleFormSubmit = async (buttonName) => {
    setLoading(true);
    const comments = document.getElementById('new_notes').value;
    const resNotes = document.getElementById('resolution_notes').value;
    const desc = document.getElementById("desc").value
    const shortdesc = document.getElementById("short_desc").value

    inc.close_notes = resNotes;
    console.log("comments::: ", comments)
    console.log("knowledge:::: ", newKnowledge)
    const payload = {}
    payload.short_description = shortdesc;
    payload.description = desc
    if (comments !== '' && comments) {
      payload.comments = comments
    }
    let invalidSubmit = false;
    if (buttonName === 'resolve' || newState === 'Closed' || newState === 'Resolved') {
      if (resolutionCode === '' || inc.close_notes === '') {
        invalidSubmit = true;
      } else {
        payload.close_notes = inc.close_notes;
        payload.close_code = resolutionCode;
        payload.resolved_at = moment().format('YYYY-MM-DD HH:mm:ss')
      }
    }

    if (invalidSubmit) {
      setAlertContent("Fill resolution information to resolve this incident");
      setAlert(true);
      setAlertType("error");
      setLoading(false);
    } else {
      inc.new_impact = newIncImpact;
      inc.new_knowledge = newKnowledge
      inc.new_urgency = newIncUrgency;
      inc.new_state = newState;
      if (payload.comments) {
        inc.workNotes.unshift({ text: payload.comments })
      }
      let fields = ['assigned_to', 'assignment_group', 'impact', 'knowledge', 'urgency', 'state', 'cmdb_ci', 'parent_incident', 'rfc', 'caused_by', 'problem_id'];
      for (const [i, row] of fields.entries()) {
        let field = 'new_' + row;
        if (inc[field] && inc[field] !== '') {
          payload[row] = inc[field]
        }
      }

      console.log("payload>>>>", payload);
      try {
        const token = localStorage.getItem("token");
        axios.defaults.headers.patch['Authorization'] = `Bearer ${token.substring(1, token.length - 1)}`;
        axios.defaults.headers.patch['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.patch['Content-Type'] = 'application/json';

        let data = {
          "appID": 1,
          "params": {
            "data": payload,
            "sysId": inc.sys_id,
            "entity": "incident",
            "methodType": "patch",
            "query": null
          }
        }
        let res = await axios.post(`${hostName}`, data);
        console.log("response>>>>", res.status)
        setLoading(false);
        if (res.status === 200) {
          setAlertContent("Saved successfully!");
          setAlert(true);
          setAlertType("success");
          document.getElementById('new_notes').value = '';
          if (shortDescForBot !== shortdesc) {
            setShortDescForBot(shortdesc)
          }
          handleRowClick({ row: inc }, false)
        } else {
          setAlertContent("Error while updating the notes, please try again.");
          setAlert(true);
          setAlertType("error");
        }
      } catch (error) {
        console.log("catch>>>", error);
        setLoading(false);
        setAlertContent("Error while updating the notes, please try again.");
        setAlert(true);
        setAlertType("error");
      }
    }
  }

  const submitUpdateIncident = async (form) => {
    try {
      setLoading(true);
      let payload = {};
      payload.short_description = form.short_description;
      payload.description = form.description;
      payload.impact = sSIncImpact;
      payload.urgency = sSIncUrgency;
      payload.comments = form.comments;
      payload.state = sSIncState;

      if (sSIncState === 'Resolved') {
        payload.close_notes = form.comments;
        payload.close_code = "resolved by caller";
        payload.resolved_at = moment().format('YYYY-MM-DD HH:mm:ss');
      }

      const token = localStorage.getItem("token");
      axios.defaults.headers.patch['Authorization'] = `Bearer ${token.substring(1, token.length - 1)}`;
      axios.defaults.headers.patch['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.patch['Content-Type'] = 'application/json';

      let data = {
        "appID": 1,
        "params": {
          "data": payload,
          "sysId": sSInc.sys_id,
          "entity": "incident",
          "methodType": "patch",
          "query": null
        }
      };

      handleSSIncClose();
      let res = await axios.post(`${hostName}`, data);

      if (res.status === 200) {
        const incidentNumber = res.data.data.result.number;
        setAlertContent(`Incident ${incidentNumber} updated successfully!`);
        setAlert(true);
        setAlertType("success");

        // Refresh the list if we're in self-service section with incident type
        if (sectionProp === 'self-service' && taskTypeProp === 'incident') {
          await getTasksList();
        }
      } else {
        setAlertContent("Error while updating incident, please try again.");
        setAlert(true);
        setAlertType("error");
      }
    } catch (error) {
      console.log("Error updating incident:", error);
      setAlertContent("Error while updating incident, please try again.");
      setAlert(true);
      setAlertType("error");
    } finally {
      setLoading(false);
    }
  };

  const submitNewIncident = async (form) => {
    try {
      setLoading(true);
      let payload = {};
      payload.short_description = form.short_description;
      payload.description = form.description;
      payload.impact = newIncImpact;
      payload.urgency = newIncUrgency;
      payload.caller_id = userID;

      axios.defaults.headers.post['x-api-key'] = `test`;
      axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.post['Content-Type'] = 'application/json';

      let data = {
        "appID": 1,
        "params": {
          "data": payload,
          "entity": "incident",
          "methodType": "post",
          "query": null
        }
      }

      handleNewIncClose();
      let res = await axios.post(`${hostName}`, data);
      console.log("response>>>>", res.status);

      if (res.status === 200) {
        console.log(res);
        const incidentNumber = res.data.data.result.number;

        // Set notification for successful creation
        setAlertContent(`Incident ${incidentNumber} created successfully!`);
        setAlert(true);
        setAlertType("success");

        // Only refresh the list if we're in the self-service section with incident type
        if (sectionProp === 'self-service' && taskTypeProp === 'incident') {
          await getTasksList();
          // Scroll to top to see the new incident
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      } else {
        setAlertContent("Error while creating incident, please try again.");
        setAlert(true);
        setAlertType("error");
      }
    } catch (error) {
      console.log("Error creating incident:", error);
      setAlertContent("Error while creating incident, please try again.");
      setAlert(true);
      setAlertType("error");
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box sx={{ flexGrow: 1, marginRight: '1%', marginLeft: '1%', minHeight: 'calc(100vh - 100px)' }}>
      {/* Notification Snackbar */}
      <Snackbar
        open={alert}
        autoHideDuration={4000}
        onClose={handleAlertClose}
        message={alertContent}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: alertType === 'success' ? '#4caf50' : '#f44336',
            minWidth: '250px'
          }
        }}
      />
      {sectionProp === 'self-service' && <Box>
        {taskType === 'incident' &&
          <Grid container sx={{ alignItems: 'center', p: 2 }}>
            <Grid size={8}><h3 style={{ padding: '1em 0 1em 0', margin: '0px' }}>Self-Service &gt; My Incidents</h3></Grid>
            <Grid size={4} display='flex' justifyContent='flex-end' alignItems='center'>
              <Chip label="Create New" color="primary" sx={{ borderRadius: '5px' }} variant='outlined' onClick={handleNewIncOpen} />
            </Grid>
          </Grid>}
        {taskType === 'request' && <h3 style={{ padding: '1em', margin: '0px' }}>Self-Service &gt; My Requests</h3>}

        {/* Search bar */}
        <Paper elevation={0} sx={{ p: 2, mb: 2, display: 'flex', alignItems: 'center' }}>
          <TextField
            fullWidth
            placeholder="Search by number or description..."
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
          />
        </Paper>

        {loading &&
          <Box sx={{ width: '100%', maxWidth: 800, mx: 'auto', p: 2 }}>
            <Skeleton animation="wave" sx={{ mb: 2, borderRadius: 2, height: 150 }} variant="rectangular" />
            <Skeleton animation="wave" sx={{ mb: 2, borderRadius: 2, height: 150 }} variant="rectangular" />
            <Skeleton animation="wave" sx={{ mb: 2, borderRadius: 2, height: 150 }} variant="rectangular" />
            <Skeleton animation="wave" sx={{ mb: 2, borderRadius: 2, height: 150 }} variant="rectangular" />
          </Box>
        }

        {!loading && <Box sx={{ width: '100%', maxWidth: 800, mx: 'auto', p: 2 }}>
          {filteredTaskList.length > 0 ?
            // Apply pagination - only show current page of items
            filteredTaskList
              .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
              .map((task, index) => (
                <Card
                  key={task.sys_id || index}
                  sx={{
                    mb: 2,
                    borderRadius: 2,
                    backgroundColor: 'white',
                    border: '1px solid #e0e0e0',
                    boxShadow: 'none',
                    transition: 'transform 0.2s',
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
                    }
                  }}
                  onClick={() => { handleSSIncOpen(task) }}
                >
                  <CardContent sx={{ p: 2 }}>
                    <Grid container spacing={1}>
                      <Grid size={7}>
                        <Typography variant="subtitle2" color="primary" fontWeight="bold">
                          {task.number}
                        </Typography>
                      </Grid>
                      <Grid size={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Chip
                          label={task.priority}
                          color={getPriorityColor(task.priority)}
                          size="small"
                          sx={{ borderRadius: '4px', fontWeight: 500 }}
                        />
                      </Grid>
                    </Grid>

                    <Typography variant="body1" sx={{ mt: 1, fontWeight: 500 }}>
                      {task.short_description || "No description provided"}
                    </Typography>

                    {task.description && (
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }} noWrap>
                        {task.description}
                      </Typography>
                    )}

                    <Divider sx={{ my: 1.5 }} />

                    <Grid container spacing={1} sx={{ mt: 0.5 }}>
                      <Grid size={6}>
                        <Typography
                          variant="body2"
                          sx={{ color: 'text.secondary', display: 'inline' }}
                        >
                          Created: {task.sys_created_on}
                        </Typography>
                      </Grid>
                      <Grid size={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {taskType === 'incident' &&
                          <Chip
                            label={task.state}
                            color={getStatusColor(task.state)}
                            size="small"
                            variant="outlined"
                            sx={{ fontSize: '0.7rem', height: '24px', fontWeight: 500 }}
                          />
                        }
                        {taskType === 'request' &&
                          <Chip
                            label={task.request_state}
                            color={getStatusColor(task.request_state)}
                            size="small"
                            variant="outlined"
                            sx={{ fontSize: '0.7rem', height: '24px', fontWeight: 500 }}
                          />
                        }
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))
            : (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                <Typography color="text.secondary">No matching items found</Typography>
              </Box>
            )
          }

          {/* Show pagination only if we have items */}
          {filteredTaskList.length > 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
              <Pagination
                count={Math.ceil(filteredTaskList.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                showFirstButton
                showLastButton
              />
            </Box>
          )}
        </Box>}

        {taskList.length === 0 && !loading &&
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
            {/*<Typography color="text.secondary">Unable to fetch data, please try again</Typography>*/}
          </Box>
        }
      </Box>}
      {sectionProp === 'my-work' &&
        <SNowListingTable filterParam="assigned" type={taskType} />
      }
      <Dialog
        open={openNewInc}
        onClose={handleNewIncClose}
        fullWidth
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            console.log(event)
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            submitNewIncident(formJson)
          },
        }}
      >
        <DialogTitle>Create Incident</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the details to create an incident
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="short_description"
            name="short_description"
            label="Short Description"
            type="text"
            fullWidth
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="description"
            name="description"
            label="Description"
            type="text"
            multiline
            fullWidth={true}
            maxRows={4}
          />
          <FormControl id="impactElement" sx={{ width: '200px', marginTop: '10px', marginRight: '10px' }}>
            <InputLabel id="impactlabel">Impact</InputLabel>
            <Select
              labelId="impactlabel"
              id="impact"
              label="Impact"
              value={newIncImpact}
              onChange={handleNewSelectChange}
            >
              <MenuItem value="1 - High">1 - High</MenuItem>
              <MenuItem value="2 - Medium">2 - Medium</MenuItem>
              <MenuItem value="3 - Low">3 - Low</MenuItem>
            </Select>
          </FormControl>
          <FormControl id="urgencyElement" sx={{ width: '200px', marginTop: '10px' }}>
            <InputLabel id="urgencylabel">Urgency</InputLabel>
            <Select
              labelId="urgencylabel"
              id="urgency"
              label="Urgency"
              value={newIncUrgency}
              onChange={handleNewSelectChangeUrg}
            >
              <MenuItem value="1 - High">1 - High</MenuItem>
              <MenuItem value="2 - Medium">2 - Medium</MenuItem>
              <MenuItem value="3 - Low">3 - Low</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNewIncClose}>Cancel</Button>
          <Button type="submit">Create</Button>
        </DialogActions>
      </Dialog>

      {sSInc &&
        <Dialog
          open={openSSInc}
          onClose={handleSSIncClose}
          fullWidth
          maxWidth="md"
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              console.log(event)
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());
              submitUpdateIncident(formJson)
            },
          }}
        >
          <DialogTitle>{sSInc.number}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="short_description"
              name="short_description"
              label="Short Description"
              type="text"
              fullWidth
              defaultValue={sSInc.short_description}
            />
            <TextField
              autoFocus
              margin="dense"
              id="description"
              name="description"
              label="Description"
              type="text"
              multiline
              fullWidth={true}
              maxRows={4}
              defaultValue={sSInc.description}
            />
            <FormControl id="impactElement" sx={{ width: '32%', marginTop: '10px', marginRight: '1.33%' }}>
              <InputLabel id="impactlabel">Impact</InputLabel>
              <Select
                labelId="impactlabel"
                id="impact"
                label="Impact"
                value={sSIncImpact}
                onChange={handleSelectChange}
              >
                <MenuItem value="1 - High">1 - High</MenuItem>
                <MenuItem value="2 - Medium">2 - Medium</MenuItem>
                <MenuItem value="3 - Low">3 - Low</MenuItem>
              </Select>
            </FormControl>
            <FormControl id="urgencyElement" sx={{ width: '32%', marginTop: '10px', marginRight: '1.33%' }}>
              <InputLabel id="urgencylabel">Urgency</InputLabel>
              <Select
                labelId="urgencylabel"
                id="urgency"
                label="Urgency"
                value={sSIncUrgency}
                onChange={handleSelectChangeUrg}
              >
                <MenuItem value="1 - High">1 - High</MenuItem>
                <MenuItem value="2 - Medium">2 - Medium</MenuItem>
                <MenuItem value="3 - Low">3 - Low</MenuItem>
              </Select>
            </FormControl>
            <FormControl id="stateElement" sx={{ width: '33%', marginTop: '10px' }}>
              <InputLabel id="statelabel">State</InputLabel>
              <Select
                labelId="statelabel"
                id="state"
                label="State"
                value={sSIncState}
                onChange={handleSelectChangeState}
              >
                <MenuItem value="New">New</MenuItem>
                <MenuItem value="In Progress">In Progress</MenuItem>
                <MenuItem value="On Hold">On Hold</MenuItem>
                <MenuItem value="Resolved">Resolved</MenuItem>
                <MenuItem value="Closed">Closed</MenuItem>
                <MenuItem value="Cancelled">Cancelled</MenuItem>
              </Select>
            </FormControl>
            <TextField
              autoFocus
              margin="dense"
              id="comments"
              name="comments"
              label="Comments"
              type="text"
              multiline
              fullWidth={true}
              maxRows={4}
            />

            {/* Work Notes Timeline Section */}
            {sSInc.workNotes && sSInc.workNotes.length > 0 && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h6" sx={{ mb: 1 }}>Work Notes History</Typography>
                <Timeline
                  sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                      flex: 0.2,
                    },
                    padding: '6px',
                    margin: 0,
                    border: '1px solid #eee',
                    borderRadius: '4px',
                    backgroundColor: '#f9f9f9'
                  }}
                >
                  {sSInc.workNotes.map((note, index) => (
                    <TimelineItem key={index}>
                      <TimelineOppositeContent color="textSecondary" sx={{ fontSize: '0.8rem' }}>
                        {note.date}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot color="primary" sx={{ my: 0.5 }} />
                        {index < sSInc.workNotes.length - 1 && <TimelineConnector />}
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="body2">{note.text}</Typography>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSSIncClose}>Cancel</Button>
            <Button type="submit">Save</Button>
          </DialogActions>
        </Dialog>
      }
    </Box>
  );
};

export default SNowListing;