import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import TemporaryDrawer from './DrawerList';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { CssBaseline, Divider, ScopedCssBaseline } from '@mui/material';
import { AccountCircleOutlined, AccountCircleRounded, Logout, LogoutOutlined, NoEncryption, NotificationsOutlined, SettingsOutlined } from '@mui/icons-material';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function PrimarySearchAppBar({menuSelect, pageName}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [user, setUser] = useLocalStorage('user');

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [dataFromDrawer, setDataFromDrawer] = React.useState({});

  console.log("Page name::: ", pageName)
  function handleDataFromDrawer(data) {
    setDataFromDrawer(data);
    menuSelect(data)
    console.log(data)
  }

  const handleProfileMenuOpen = (event) => {
    console.log("user:::: in bar", user);
    let usert = user
    if (typeof user === 'string') {
      usert = JSON.parse(user);
    }
    
    setUser(usert);
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMenuSelect = () => {
    navigate("/settings");
    handleMenuClose()
  }

  const handleLogout = () => {
    navigate("/");
    localStorage.setItem("user", null);
    localStorage.setItem("token", null);
    localStorage.setItem("refreshToken", null);
    localStorage.setItem("role", null);
    localStorage.setItem("userID", null);
    localStorage.setItem("conversation_id", null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        },
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Box sx={{ backgroundColor: 'white' }}>
        <MenuItem><AccountCircleOutlined /><Typography sx={{ ml: 0.5 }}>{user.first_name} {user.last_name}</Typography></MenuItem>
        <Divider />
        <MenuItem onClick={handleMenuSelect}><SettingsOutlined /><Typography sx={{ ml: 0.5 }}>Settings</Typography></MenuItem>
        <MenuItem onClick={handleLogout}><Logout /><Typography sx={{ ml: 0.5 }}>Logout</Typography></MenuItem>
      </Box>
    </Menu>

  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const handleSearch = () => {
    console.log("called search with message", document.getElementById("searchbar").value)
  }

  const handleHomeClick = () => {
    navigate("/home?main=true");
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <ScopedCssBaseline />
      <AppBar position="fixed" sx={{ backgroundColor: "#181826", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <TemporaryDrawer menuSelection={handleDataFromDrawer} permanent={false} pageName={pageName}></TemporaryDrawer>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
            onClick={handleHomeClick}
          >
            KOGNIV
          </Typography>
          <Box sx={{ flexGrow: 1, marginLeft: '1em', display: { xs: 'none', md: 'flex' } }} >
            <Typography
              variant="p"
              noWrap
              component="div"
              size='medium'
              sx={{ display: { xs: 'none', sm: 'block', letterSpacing: '0.1em', marginLeft: '1em', marginRight: '1em' } }}
              onClick={handleHomeClick}
            >
              Home
            </Typography>
            <Typography
              variant="p"
              noWrap
              component="div"
              size='medium'
              sx={{ display: { xs: 'none', sm: 'block', letterSpacing: '0.1em', marginLeft: '1em', marginRight: '1em' } }}
            >
              Knowledge
            </Typography>
            <Typography
              variant="p"
              noWrap
              component="div"
              size='medium'
              sx={{ display: { xs: 'none', sm: 'block', letterSpacing: '0.1em', marginLeft: '1em', marginRight: '1em' } }}
            >
              News
            </Typography>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsOutlined sx={{ color: '#53B146' }} />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircleOutlined sx={{ color: '#53B146' }} />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {pageName === 'home' && <TemporaryDrawer menuSelection={handleDataFromDrawer} permanent={true} pageName={pageName}></TemporaryDrawer>}
    </Box>
  );
}
