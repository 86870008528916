import ChatWidget from "./ChatWidget";
import PrimarySearchAppBar from "./PrimarySearchAppBar";
import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import axios from "axios";
import { useEffect, useState } from "react";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Box, Select, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, CircularProgress, Chip } from "@mui/material";
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { LoadingButton } from "@mui/lab";
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import Alert from '@mui/material/Alert';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import moment from 'moment';
import axiosInstance from '../axios';
import { useSearchParams } from "react-router-dom";
import ResourceCardMsg from "./ChatWidget/resourceCardMsg";
import { useNavigate } from "react-router-dom";

const ChatWidgetWMemo = React.memo(ChatWidget)

let botLoaded = false;

const hostName = 'https://kogniv-iservice-gvgmhpeuf5eaaghu.centralus-01.azurewebsites.net/api/main';
//const hostName = 'http://localhost:8000/api/main'

const SNowListingTable = ({ filterParam, taskType }) => {
    const [srList, setSrList] = useState([]);
    const [srListClosed, setSrListClosed] = useState([]);
    const [visible, setVisible] = useState(false);
    const [inc, setInc] = useState(null);
    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [loadedLists, setLoadedLists] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertContent, setAlertContent] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [incState, setIncState] = useState('open');
    let rows = [];
    const [userList, setUserList] = useState([]);
    const [userGroupList, setUserGroupList] = useState([]);
    const [ciList, setCiList] = useState([]);
    const [incidentsList, setIncidentsList] = useState([]);
    const [changesList, setChangesList] = useState([]);
    const [problemsList, setProblemsList] = useState([]);
    const [openUser, setOpenUser] = useState(false);
    const [openNewInc, setOpenNewInc] = useState(false);
    const [newIncImpact, setNewIncImpact] = useState('');
    const [newIncUrgency, setNewIncUrgency] = useState('');
    const [newState, setNewState] = useState('');
    const [resolutionCode, setResolutionCode] = useState('');
    const [newKnowledge, setNewKnowledge] = useState(false);
    const [shortDescForBot, setShortDescForBot] = useState(null);
    const [botResponse, setBotResponse] = useState(null);
    const [botLoading, setBotLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [incidentFilterModel, setIncidentFilterModel] = useState({ filterModel: { items: [] } })
    const [refContent, setRefContent] = useState({ kb: [], inc: [] });
    const navigate = useNavigate();

    const handleNewSelectChange = (event) => {
        setNewIncImpact(event.target.value)
    };

    const handleNewSelectChangeUrg = (event) => {
        setNewIncUrgency(event.target.value)
    };


    const handleSelectChange = (event) => {
        setNewIncImpact(event.target.value);
    };

    const handleSelectChangeUrg = (event) => {
        setNewIncUrgency(event.target.value);
    };

    const handleSelectChangeIncState = (event) => {
        setNewState(event.target.value);
    };

    const handleSelectChangeIncResolutionCode = (event) => {
        setResolutionCode(event.target.value);
    };

    const handleKnowledgeToggle = (event) => {
        setNewKnowledge(event.target.checked)
    }

    const handleClose = () => {
        setOpenUser(false);
    };

    const handleNewIncOpen = () => {
        setOpenNewInc(true);
        setNewIncImpact('');
        setNewIncUrgency('');
    };

    const handleNewIncClose = () => {
        setOpenNewInc(false);
        setNewIncImpact('');
        setNewIncUrgency('');
    };

    const [modalData, setModalData] = useState([]);
    const handleChange = (event, incState) => {
        setIncState(incState);
        rows = srListClosed;
    };

    function handleModalOpenUsers(type, field) {
        let modalData = {}
        modalData.type = type;
        modalData.fieldName = field;
        modalData.data = {}
        if (type === 'user') {
            modalData.data.columns = [
                { field: 'id', headerName: 'ID', width: 70, hideable: true },
                { field: 'sys_id', headerName: 'Sys ID', width: 70, hideable: true },
                { field: 'name', headerName: 'Name', width: 200 },
                { field: 'sys_domain.value', headerName: 'User Group', width: 300, hideable: true },
                { field: 'first_name', headerName: 'First Name', width: 130, sortable: true, hideable: true },
                { field: 'last_name', headerName: 'Last Name', width: 130, hideable: true },
                { field: 'email', headerName: 'Email', width: 300, hideable: true }

            ];
            modalData.data.rows = userList
        } else if (type === 'userGrp') {
            modalData.data.columns = [
                { field: 'id', headerName: 'ID', width: 70, hideable: true },
                { field: 'sys_id', headerName: 'Sys ID', width: 70, hideable: true },
                { field: 'name', headerName: 'Name', width: 500 }
            ];
            console.log("user gorup list>>>>>  ", userGroupList)
            modalData.data.rows = userGroupList
        } else if (type === 'ci') {
            modalData.data.columns = [
                { field: 'id', headerName: 'ID', width: 70, hideable: true },
                { field: 'sys_id', headerName: 'Sys ID', width: 70, hideable: true },
                { field: 'name', headerName: 'Name', width: 200 },
                { field: 'manufacturer', headerName: 'Manufacturer', width: 130, sortable: true, hideable: true },
                { field: 'subcategory', headerName: 'Class', width: 130, hideable: true },
                { field: 'assigned_to.value', headerName: 'Assigned To', width: 300, hideable: true }
            ];
            console.log("ci list>>>>  ", ciList)
            modalData.data.rows = ciList
        } else if (type === 'inc') {
            modalData.data.columns = [
                { field: 'id', headerName: 'ID', width: 70, hideable: true },
                { field: 'sys_id', headerName: 'Sys ID', width: 70, hideable: true },
                { field: 'number', headerName: 'Number', width: 200 },
                { field: 'assigned_to.value', headerName: 'Assigned To', width: 130, sortable: true, hideable: true },
                { field: 'assignment_group.value', headerName: 'Assignment Group', width: 130, hideable: true },
                { field: 'short_description', headerName: 'Short Description', width: 300, hideable: true }
            ];
            modalData.data.rows = incidentsList
        } else if (type === 'prb') {
            modalData.data.columns = [
                { field: 'id', headerName: 'ID', width: 70, hideable: true },
                { field: 'sys_id', headerName: 'Sys ID', width: 70, hideable: true },
                { field: 'number', headerName: 'Number', width: 200 },
                { field: 'assigned_to.value', headerName: 'Assigned To', width: 130, sortable: true, hideable: true },
                { field: 'assignment_group.value', headerName: 'Assignment Group', width: 130, hideable: true },
                { field: 'short_description', headerName: 'Short Description', width: 300, hideable: true }
            ];
            modalData.data.rows = problemsList
        } else if (type === 'chg') {
            modalData.data.columns = [
                { field: 'id', headerName: 'ID', width: 70, hideable: true },
                { field: 'sys_id', headerName: 'Sys ID', width: 70, hideable: true },
                { field: 'number', headerName: 'Number', width: 200 },
                { field: 'assigned_to.value', headerName: 'Assigned To', width: 130, sortable: true, hideable: true },
                { field: 'assignment_group.value', headerName: 'Assignment Group', width: 130, hideable: true },
                { field: 'short_description', headerName: 'Short Description', width: 300, hideable: true }
            ];
            modalData.data.rows = changesList
        }
        modalData.data.rowLength = modalData.data.rows.length;
        modalData.data.maxColumns = 10

        modalData.paginationModel = { page: 0, pageSize: 20 };
        console.log('modaldata >>>>  ', modalData)
        setModalData(modalData);
        setOpenUser(true)
    }

    let userRole = localStorage.getItem("role").toLocaleLowerCase();
    userRole = userRole.substring(1, userRole.length - 1);
    //userRole = 'itil';
    let userID = localStorage.getItem("userID");
    userID = userID.substring(1, userID.length - 1)
    console.log("typeof userid::::::::::::", userID)

    const getSRList = async () => {
        try {

            filterMyAssignment(filterParam)

            setTableLoading(true);
            const token = localStorage.getItem("token");
            let user = localStorage.getItem("user").toLocaleLowerCase();
            user = user.substring(1, user.length - 1)
            axios.defaults.headers.post['x-api-key'] = `test`;
            axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            let data = {}
            if (userRole === 'employee' || userRole === 'approver') {
                data = {
                    "appID": 1,
                    "params": {
                        "data": null,
                        "entity": "incident",
                        "methodType": "get",
                        "query": {
                            "sysparm_query": "active=true^caller_id=" + userID,
                            "sysparm_fields": "number,state,sys_id,sys_created_on,priority,short_description,due_date,assigned_to,assignment_group,caller_id",
                            "sysparm_display_value": "true"
                        }
                    }
                }
            } else if (userRole === 'itil' || userRole === 'super_admin') {
                data = {
                    "appID": 1,
                    "params": {
                        "data": null,
                        "entity": "incident",
                        "methodType": "get",
                        "query": {
                            "sysparm_query": {
                                "active": true
                            },
                            "sysparm_fields": "number,state,sys_id,sys_created_on,priority,short_description,due_date,assigned_to,assignment_group,caller_id",
                            "sysparm_display_value": "true"
                        }
                    }
                }
            }

            let res = axios.post(`${hostName}`, data).then((res) => {
                if (res.status === 200) {
                    let data = [];
                    let closedData = [];
                    if (userRole === 'employee' || userRole === 'approver') {
                        for (const [i, row] of res.data.data.result.entries()) {
                            row.id = i
                            if (typeof row.assigned_to === 'object') {
                                row.assigned_to_table = row.assigned_to.display_value
                                row.assigned_to_val_table = row.assigned_to.link
                            }
                            if (typeof row.assignment_group === 'object') {
                                row.assignment_group_table = row.assignment_group.display_value
                                row.assignment_group_val_table = row.assignment_group.link
                            }
                            if (typeof row.caller_id === 'object') {
                                row.caller_val_table = row.caller_id.link
                            }
                            data.push(row)
                        }

                    } else if (userRole === 'itil' || userRole === 'super_admin') {
                        console.log("data in itil>>> ", res.data)
                        for (const [i, row] of res.data.data.result.entries()) {
                            row.id = i
                            if (typeof row.assigned_to === 'object') {
                                row.assigned_to_table = row.assigned_to.display_value
                                row.assigned_to_val_table = row.assigned_to.link
                            }
                            if (typeof row.assignment_group === 'object') {
                                row.assignment_group_table = row.assignment_group.display_value
                                row.assignment_group_val_table = row.assignment_group.link
                            }
                            if (typeof row.caller_id === 'object') {
                                row.caller_val_table = row.caller_id.link
                            }
                        }
                        data = res.data.data.result;
                    }
                    const SR = data;
                    setSrList(SR);
                    setSrListClosed(closedData);
                    setTableLoading(false);

                } else {
                    console.log(res.status);
                    setTableLoading(false);

                }
            })

        }
        catch (error) {
            console.log("error while fetching inc list:::", error)

            setTableLoading(false);

        }
    };
    useEffect(() => {
        getSRList()
    }, []);

    const columns = [
        { field: 'id', headerName: 'ID', width: 70, hideable: true, filterable: false },
        { field: 'sys_id', headerName: 'Sys ID', width: 70, hideable: true, filterable: false },
        { field: 'number', headerName: 'Incident ID', width: 100, filterable: true },
        { field: 'sys_created_on', headerName: 'Created Date', width: 160, sortable: true, hideable: true, filterable: false },
        { field: 'priority', headerName: 'Priority', width: 100, hideable: true, filterable: true },
        { field: 'state', headerName: 'State', width: 100, hideable: true, filterable: true },
        { field: 'short_description', headerName: 'Short Description', sortable: false, width: 300, hideable: true, filterable: false },
        { field: 'due_date', headerName: 'Due Date', width: 100, sortable: true, hideable: true, filterable: true },
        { field: 'assigned_to_table', headerName: 'Assigned To', width: 150, sortable: true, hideable: true, filterable: true },
        { field: 'assignment_group_table', headerName: 'Assignment Group', width: 250, sortable: true, hideable: true, filterable: true },
        { field: 'assigned_to_val_table', headerName: 'Assigned To val', width: 150, sortable: true, hideable: true, filterable: true },
        { field: 'assignment_group_val_table', headerName: 'Assignment Group val', width: 250, sortable: true, hideable: true, filterable: true },
        { field: 'caller_val_table', headerName: 'Caller ID', width: 250, sortable: true, hideable: true, filterable: true }
    ];

    const filterMyAssignment = (filterParam) => {
        if (filterParam && (userRole === 'itil' || userRole === 'super_admin')) {
            if (filterParam.includes('grp_assigned')) {
                /*incidentFilterModel = {
                  filterModel: {
                    items: [{ field: 'assignment_group_val_table', operator: 'contains', value: userID }]
                  }
                }*/
                setIncidentFilterModel({ filterModel: { items: [] } })
                setSearchParams("filter=grp_assigned")
            } else if (filterParam.includes('assigned')) {
                let filter = {
                    filterModel: {
                        items: [{ field: 'assigned_to_val_table', operator: 'contains', value: userID }]
                    }
                }
                setIncidentFilterModel(filter)
                setSearchParams("filter=assigned")
            } else if (filterParam.includes('my_inc')) {
                let filter = {
                    filterModel: {
                        items: [{ field: 'caller_val_table', operator: 'contains', value: userID }]
                    }
                }
                setIncidentFilterModel(filter)
                setSearchParams("filter=my_inc")
            }
        } else {
            setIncidentFilterModel({ filterModel: { items: [] } })
            setSearchParams("filter=null")
        }
    }



    if (incState === 'open') {
        rows = srList
    } else {
        rows = srListClosed
    }

    const data = {
        "rows": rows,
        "columns": columns,
        "rowLength": rows.length,
        "maxColumns": 50
    }

    const paginationModel = { page: 0, pageSize: 10 };

    async function getKnowledgeresponse(short_description) {
        setBotLoading(true);
        localStorage.setItem('aa_conv_id', null)
        console.log('bot loading:::', botLoading)
        try {
            setShortDescForBot(short_description);
            const token = localStorage.getItem("token");
            let customHeaders = {};
            customHeaders['Authorization'] = 'Bearer ' + token.substring(1, token.length - 1);
            customHeaders['Access-Control-Allow-Origin'] = '*';
            customHeaders['Content-Type'] = 'application/json';
            let message = "How can I assist you today?";
            const response = await axiosInstance.post('https://api.kogniv.com/api/v1/chat/agent-assist', {
                //"message": `Give me the resolution steps in less than 6 steps in a formatted way for the issue: ${short_description}. Steps must be enclosed in HTML tags where the steps should be in list tag, header with underline and the subheaders with the bold tags with proper indentations.`
                "message": short_description,
                "enable_search": false
            }, { customHeaders });
            console.log('bot loading end:::', botLoading)
            if (response.status === 200) {
                localStorage.setItem('aa_conv_id', response.data.thread_id)
                if (response.data.response === "") {
                    message = "How can I assist you today?"
                } else {
                    message = response.data.response
                }
            }
            setBotResponse(message);
            setBotLoading(false);
        } catch (err) {
            console.log('bot loading end:::', botLoading)
            console.log("error in getting knowledge base", err);
            setBotResponse({ response: { msg: "Sorry, Unable to get related information" } })
            setBotLoading(false);
        }
        botLoaded = true;

    }

    const fetchRefs = async () => {
        let fetchedRefs = false;
        let fetchedCount = 0;
        if (inc && inc.kb && inc.kb.length > 0) {
            for (const [i, ref] of inc.kb.entries()) {
                axios.defaults.headers.post['x-api-key'] = `test`;
                axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
                axios.defaults.headers.post['Content-Type'] = 'application/json';
                let data = {
                    "appID": 1,
                    "params": {
                        "data": null,
                        "entity": "",
                        "methodType": "get",
                        "query": {
                            "sysparm_query": {
                                "number": ref
                            }
                        }
                    }
                };
                if (ref.includes("KB")) {
                    data.params.entity = "knowledge";
                    data.params.query.sysparm_fields = "number,short_description,text,published"
                } else if (ref.includes("INC")) {
                    data.params.entity = "incident"
                    data.params.query.sysparm_fields = "number,short_description,description,comments_and_work_notes,close_notes"
                    data.params.query.sysparm_display_value = "true"
                }
                try {
                    let res = await axios.post(`${hostName}`, data);
                    if (res.status === 200) {
                        if (res.data.data.result.length > 0) {
                            let refTemp = refContent;
                            console.log("ref:::", res)
                            if (ref.includes("KB")) {
                                refTemp.kb.push(res.data.data.result[0]);
                            } else if (ref.includes("INC")) {
                                let inc = res.data.data.result[0];
                                console.log("incident:: ", inc)

                                inc.comments_and_work_notes = inc.comments_and_work_notes.replace('\n\n', '\n');
                                let tempworkNotes = inc.comments_and_work_notes.split('\n');
                                let workNotes = [];
                                let note = {}
                                tempworkNotes = tempworkNotes.filter(e => e !== '');
                                for (const [i, row] of tempworkNotes.entries()) {

                                    if (i % 2 === 0) {
                                        note = {}
                                        note.date = row;
                                    } else {
                                        note.text = row;
                                        workNotes.push(note);
                                    }
                                }
                                console.log("workNotes:: ", workNotes)
                                inc.workNotes = workNotes;

                                refTemp.inc.push(inc);
                            }
                            fetchedRefs = refTemp;
                            setRefContent(refTemp)
                        }
                        fetchedCount++;
                        if (fetchedCount === inc.kb.length) {
                            fetchedRefs = true;
                        }
                    }
                } catch (err) {
                    console.log("error while fetching reference:: ", ref)
                    console.log("Error stack::", err);
                    fetchedCount++;
                    if (fetchedCount === inc.kb.length) {
                        fetchedRefs = true;
                    }
                }
            }
        }
    }

    const handleRowClick = async (params) => {
        console.log("row click called", params)
        navigate(`/my-incidents?num=${params.row.number}`)
        /*
        if (taskTypeProp === 'incident') {
            navigate(`/my-incidents?num=${params}`)
        } else if (taskTypeProp === 'incident') {
            navigate(`/my-sr?num=${params}`)
        }
            */
    };

    //const memoizedRowClick = React.useCallback(handleRowClick, []);
    //console.log("memoizedUpdateCount::: ", memoizedRowClick)

    const backToList = () => {
        setVisible(false);
        setShortDescForBot(null);
        setBotResponse(null);
        setBotLoading(true);
        setAlert(false);
        getSRList();
        setRefContent({ kb: [], inc: [] })
    }

    const handleUserModalClick = async (params) => {
        console.log(params);
        let fieldName = modalData.fieldName;
        let inctemp = null;
        switch (fieldName) {
            case 'assigned_to':
                inc.new_assigned_to = params.row.sys_id;
                if (inc.assigned_to === '') {
                    inc.assigned_to = {}
                }
                inc.assigned_to.value = params.row.sys_id;
                inc.assigned_to.display_value = params.row.name;
                document.getElementById('assigned_to_val').value = params.row.name;
                break;
            case 'assignment_group':
                inc.new_assignment_group = params.row.sys_id;
                if (inc.assignment_group === '') {
                    inc.assignment_group = {}
                }
                inc.assignment_group.value = params.row.sys_id;
                inc.assignment_group.display_value = params.row.name;
                document.getElementById('assignGrp').value = params.row.name;
                break;
            case 'cmdb_ci':
                inc.new_cmdb_ci = params.row.sys_id;
                if (inc.cmdb_ci === '') {
                    inc.cmdb_ci = {}
                }
                inc.cmdb_ci.value = params.row.sys_id;
                inc.cmdb_ci.display_value = params.row.name;
                document.getElementById('congif_item').value = params.row.name;
                break;
            case 'parent_incident':
                inc.new_parent_incident = params.row.sys_id;
                if (inc.parent_incident === '') {
                    inc.parent_incident = {}
                }
                inc.parent_incident.value = params.row.sys_id;
                inc.parent_incident.display_value = params.row.number;
                document.getElementById('parent_incident').value = params.row.number;
                break;
            case 'rfc':
                inc.new_rfc = params.row.sys_id;
                if (inc.rfc === '') {
                    inc.rfc = {}
                }
                inc.rfc.value = params.row.sys_id;
                inc.rfc.display_value = params.row.number;
                document.getElementById('rfc').value = params.row.number;
                break;
            case 'caused_by':
                inc.new_caused_by = params.row.sys_id;
                if (inc.caused_by === '') {
                    inc.caused_by = {}
                }
                inc.caused_by.value = params.row.sys_id;
                inc.caused_by.display_value = params.row.number;
                document.getElementById('caused_by').value = params.row.number;
                break;
            case 'problem_id':
                inc.new_problem_id = params.row.sys_id;
                if (inc.problem_id === '') {
                    inc.problem_id = {}
                }
                inc.problem_id.value = params.row.sys_id;
                inc.problem_id.display_value = params.row.number;
                document.getElementById('problem_id').value = params.row.number;
                break;
        }
        inctemp = inc;
        setInc(inctemp);
        setOpenUser(false);
    }

    const handleFormSubmit = async (buttonName) => {
        setLoading(true);
        const comments = document.getElementById('new_notes').value;
        const resNotes = document.getElementById('resolution_notes').value;
        const desc = document.getElementById("desc").value
        const shortdesc = document.getElementById("short_desc").value

        inc.close_notes = resNotes;
        console.log("comments::: ", comments)
        console.log("knowledge:::: ", newKnowledge)
        const payload = {}
        payload.short_description = shortdesc;
        payload.description = desc
        if (comments !== '' && comments) {
            payload.comments = comments
        }
        let invalidSubmit = false;
        if (buttonName === 'resolve' || newState === 'Closed' || newState === 'Resolved') {
            if (resolutionCode === '' || inc.close_notes === '') {
                invalidSubmit = true;
            } else {

                //payload.closed_by = userID;
                payload.close_notes = inc.close_notes;
                payload.close_code = resolutionCode;
                payload.resolved_at = moment().format('YYYY-MM-DD HH:mm:ss')

            }
        }

        if (invalidSubmit) {
            setAlertContent("Fill resolution information to resolve this incident");
            setAlert(true);
            //setTimeout(setAlert(false),3000)
            setAlertType("error");
            setLoading(false);
        } else {
            inc.new_impact = newIncImpact;
            inc.new_knowledge = newKnowledge
            inc.new_urgency = newIncUrgency;
            inc.new_state = newState;
            if (payload.comments) {
                inc.workNotes.unshift({ text: payload.comments })
            }
            let fields = ['assigned_to', 'assignment_group', 'impact', 'knowledge', 'urgency', 'state', 'cmdb_ci', 'parent_incident', 'rfc', 'caused_by', 'problem_id'];
            for (const [i, row] of fields.entries()) {
                let field = 'new_' + row;
                if (inc[field] && inc[field] !== '') {
                    payload[row] = inc[field]
                }
            }

            console.log("payload>>>>", payload);
            try {
                const token = localStorage.getItem("token");
                axios.defaults.headers.patch['Authorization'] = `Bearer ${token.substring(1, token.length - 1)}`;
                axios.defaults.headers.patch['Access-Control-Allow-Origin'] = '*';
                axios.defaults.headers.patch['Content-Type'] = 'application/json';

                let data = {
                    "appID": 1,
                    "params": {
                        "data": payload,
                        "sysId": inc.sys_id,
                        "entity": "incident",
                        "methodType": "patch",
                        "query": null
                    }
                }
                let res = await axios.post(`${hostName}`, data);
                console.log("response>>>>", res.status)
                setLoading(false);
                if (res.status === 200) {
                    setAlertContent("Saved successfully!");
                    setAlert(true);
                    //setTimeout(setAlert(false),3000)
                    setAlertType("success");
                    document.getElementById('new_notes').value = '';
                    if (shortDescForBot !== shortdesc) {
                        setShortDescForBot(shortdesc)
                    }
                    handleRowClick({ row: inc }, false)
                } else {
                    setAlertContent("Error while updating the notes, please try again.");
                    setAlert(true);
                    //setTimeout(setAlert(false),3000)
                    setAlertType("error");
                }

            } catch (error) {
                console.log("catch>>>", error);
                setLoading(false);
                setAlertContent("Error while updating the notes, please try again.");
                setAlert(true);
                //setTimeout(setAlert(false),3000)
                setAlertType("error");
            }
        }
    }

    const attachToInc = async (kb) => {
        console.log("KB to be attached to inc:: ", kb)
        try {
            const token = localStorage.getItem("token");
            axios.defaults.headers.patch['Authorization'] = `Bearer ${token.substring(1, token.length - 1)}`;
            axios.defaults.headers.patch['Access-Control-Allow-Origin'] = '*';
            axios.defaults.headers.patch['Content-Type'] = 'application/json';

            let data = {
                "appID": 1,
                "params": {
                    "data": { comments: 'Attached KB Article: ' + kb },
                    "sysId": inc.sys_id,
                    "entity": "incident",
                    "methodType": "patch",
                    "query": null
                }
            }
            let res = await axios.post(`${hostName}`, data);
            console.log("response>>>>", res.status)
            setLoading(false);
            if (res.status === 200) {
                setAlertContent("Saved successfully!");
                setAlert(true);
                //setTimeout(setAlert(false),3000)
                setAlertType("success");
                inc.workNotes.unshift({ text: 'Attached KB Article: ' + kb });

                document.getElementById('new_notes').value = '';
            } else {
                setAlertContent("Error while attaching the KB article, please try again.");
                setAlert(true);
                //setTimeout(setAlert(false),3000)
                setAlertType("error");
            }

        } catch (error) {
            console.log("catch>>>", error);
            setLoading(false);
            setAlertContent("Error while attaching the KB article, please try again.");
            setAlert(true);
            //setTimeout(setAlert(false),3000)
            setAlertType("error");
        }
    }

    const chatBotChild = React.useMemo(() => {
        return <ChatWidgetWMemo botName='knowledgeAssist' entityType='incident' /*shortDesc={memoizedShortDesc}*/ entity={botResponse} attachment={attachToInc} /*botLoaded={botLoaded}*/ />
    }, [botResponse])


    const submitNewIncident = async (form) => {
        let payload = {};
        payload.short_description = form.short_description;
        payload.description = form.description;
        payload.impact = newIncImpact
        payload.urgency = newIncUrgency
        payload.caller_id = userID
        axios.defaults.headers.post['x-api-key'] = `test`;
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.post['Content-Type'] = 'application/json';
    
        let data = {
          "appID": 1,
          "params": {
            "data": payload,
            "entity": "incident",
            "methodType": "post",
            "query": null
          }
        }
        handleNewIncClose();
        let res = await axios.post(`${hostName}`, data);
        console.log("response>>>>", res.status);
        if (res.status === 200) {
          console.log(res)
          setAlertContent("Incident " + res.data.data.result.number + " created successfully!");
          setAlert(true);
          setAlertType("success");
          await getSRList();
          setAlert(false);
          //setTimeout(setAlert(false),3000)
    
        } else {
          setAlertContent("Error while creating incident, please try again.");
          setAlert(true);
          //setTimeout(setAlert(false),3000)
          setAlertType("error");
        }
      }

    return (
        <Box sx={{ flexGrow: 1 }}>

            {!visible && (<Grid container spacing={2}><Grid size={12}><Paper sx={{ padding: '2%', boxShadow: 'none' }}>
                {alert ? <Alert severity={alertType}>{alertContent}</Alert> : <></>}
                <h3 style={{ display: 'inline-block' }}>My Work &gt; Incidents</h3>
                <Chip label="Create New" color="primary" sx={{ float: 'right',borderRadius: '5px' }} variant='outlined' onClick={handleNewIncOpen} />
                <DataGrid
                    sx={{ border: 'solid thin #cccccc', borderRadius: '5px' }}
                    {...data}
                    loading={tableLoading}

                    slotProps={{
                        loadingOverlay: {
                            noRowsVariant: 'linear-progress',
                            variant: 'linear-progress'
                        },
                    }}
                    disableMultipleRowSelection={true}
                    initialState={{
                        pagination: { paginationModel },
                        pinnedColumns: {
                            left: ['number'],
                        },
                        filter: incidentFilterModel
                    }}
                    columnVisibilityModel={{
                        // Hide columns status and traderName, the other columns will remain visible
                        id: false,
                        sys_id: false,
                        assigned_to_val_table: false,
                        assignment_group_val_table: false,
                        caller_val_table: false,
                        due_date: false,
                        assigned_to_table: false,
                        assignment_group_table: false,
                        caller_id: false
                    }}
                    filterModel={incidentFilterModel.filterModel}
                    pageSizeOptions={[5, 10]}
                    checkboxSelection={false}
                    onRowClick={handleRowClick}
                />
                {/*filterModel={incidentFilterModel.filterModel}
                    onFilterModelChange={(newFilterModel) => {
                        let filter = { filterModel: newFilterModel };
                        setIncidentFilterModel(filter)
                    }
                    }
                    slots={{ toolbar: GridToolbar }}*/}
            </Paper>
            </Grid></Grid>)}
            <Dialog
                open={openNewInc}
                onClose={handleNewIncClose}
                fullWidth
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        console.log(event)
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        submitNewIncident(formJson)
                    },
                }}
            >
                <DialogTitle>Create Incident</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the details to create an incident
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="short_description"
                        name="short_description"
                        label="Short Description"
                        type="text"
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="description"
                        name="description"
                        label="Description"
                        type="text"
                        multiline
                        fullWidth={true}
                        maxRows={4}
                    />
                    <FormControl id="impactElement" sx={{ width: '200px', marginTop: '10px', marginRight: '10px' }}>
                        <InputLabel id="impactlabel">Impact</InputLabel>
                        <Select
                            labelId="impactlabel"
                            id="impact"
                            label="Impact"
                            value={newIncImpact}
                            onChange={handleNewSelectChange}
                        >
                            <MenuItem value="1 - High">1 - High</MenuItem>
                            <MenuItem value="2 - Medium">2 - Medium</MenuItem>
                            <MenuItem value="3 - Low">3 - Low</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl id="urgencyElement" sx={{ width: '200px', marginTop: '10px' }}>
                        <InputLabel id="urgencylabel">Urgency</InputLabel>
                        <Select
                            labelId="urgencylabel"
                            id="urgency"
                            label="Urgency"
                            value={newIncUrgency}
                            onChange={handleNewSelectChangeUrg}
                        >
                            <MenuItem value="1 - High">1 - High</MenuItem>
                            <MenuItem value="2 - Medium">2 - Medium</MenuItem>
                            <MenuItem value="3 - Low">3 - Low</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNewIncClose}>Cancel</Button>
                    <Button type="submit">Create</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default SNowListingTable;

/*

Taken backup on : 12-02-2025
****************************
<ToggleButtonGroup
                  
                  color="primary"
                  value={incState}
                  exclusive
                  onChange={handleChange}
                  aria-label="Platform"
                  sx={{ float: 'right' }}
                >
                  <ToggleButton value="open">Active</ToggleButton>
                  <ToggleButton value="closed">Closed</ToggleButton>
                </ToggleButtonGroup>
*/