import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import { useTheme } from '@mui/material/styles';
import "./Options.css";

const FeedbackOptions = (props) => {
  console.log("props in options widget::", props)
  const theme = useTheme();
  const [uniqueId, setUniqueId] = useState('');
  
  useEffect(() => {
    // Generate a unique ID when the component mounts
    setUniqueId(`feedback-container-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`);
  }, []);

  return <div className="options-container">
    <Box sx={{ display: 'flex', gap: 1 }} id={uniqueId}>
      <IconButton 
        onClick={() => props.actionProvider.handleFeedback({option:'thumbsUp',id:uniqueId})}
        sx={{ 
          color: theme.palette.tertiary.main,
          '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
        }}
        aria-label="thumbs up"
        size="small"
      >
        <ThumbUpOutlinedIcon fontSize="small" />
      </IconButton>
      
      <IconButton 
        onClick={() => props.actionProvider.handleFeedback({option:'thumbsDown',id:uniqueId})}
        sx={{ 
          color: theme.palette.tertiary.main,
          '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
        }}
        aria-label="thumbs down"
        size="small"
      >
        <ThumbDownOutlinedIcon fontSize="small" />
      </IconButton>
    </Box>
  </div>;
};

export default FeedbackOptions;
