import { styles } from "./styles";
import React, { memo, useEffect, useRef, useState } from "react";
import Chatbot, { createChatBotMessage } from "react-chatbot-kit";
import 'react-chatbot-kit/build/main.css';
import config from "../ChatBot/config";
import MessageParser from "../ChatBot/MessageParser";
import ActionProvider from "../ChatBot/ActionProvider";
import Box from '@mui/material/Box';
import { v4 as uuidv4 } from 'uuid';
import './chatbotCustomStyle.css';
import axios from 'axios';
import { Button, CircularProgress, CssBaseline } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import ResourceCardMsg from "./resourceCardMsg";

const ResourceCardMsgMemo = React.memo(ResourceCardMsg)

// TabbedContainer Component
const TabbedContainer = ({ children, relatedIncidents = { shortDesc: [], cmdbCI: [] }, msg, attach, knowledgeLoading = false, knowledgeBase = { shortDesc: [], cmdbCI: [] } }) => {
    const [activeTab, setActiveTab] = useState('chat');
    const [chatContent, setChatContent] = useState(null);
    const [loading, setLoading] = useState(false);

    const [shortDescPage, setShortDescPage] = useState(1);
    const [cmdbCIPage, setCmdbCIPage] = useState(1);
    const [shortDescKnowledgePage, setShortDescKnowledgePage] = useState(1);
    const [cmdbCIKnowledgePage, setCmdbCIKnowledgePage] = useState(1);
    const itemsPerPage = 3;

    const theme = useTheme();
    // When the component first mounts, store the initial children
    useEffect(() => {
        if (!chatContent) {
            setChatContent(children);
        }
    }, []);

    useEffect(() => {
        setShortDescPage(1);
        setCmdbCIPage(1);
        setShortDescKnowledgePage(1);
        setCmdbCIKnowledgePage(1);
    }, [relatedIncidents, knowledgeBase]);
    /*
    useEffect(() => {
        const fetchKnowledgeBaseItems = async (references) => {
            setLoading(true);
            const fetchedKnowledgeItems = [];
            const hostName = 'https://kogniv-iservice-gvgmhpeuf5eaaghu.centralus-01.azurewebsites.net/api/main';

            try {
                // Use the specific logic provided for fetching KB articles
                for (const [i, ref] of references.entries()) {
                    axios.defaults.headers.post['x-api-key'] = `test`;
                    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
                    axios.defaults.headers.post['Content-Type'] = 'application/json';

                    let data = {
                        "appID": 1,
                        "params": {
                            "data": null,
                            "entity": "",
                            "methodType": "get",
                            "query": {
                                "sysparm_query": {
                                    "number": ref
                                }
                            }
                        }
                    };

                    data.params.entity = "knowledge";
                    data.params.query.sysparm_fields = "number,short_description,text,published";

                    try {
                        let res = await axios.post(`${hostName}`, data);
                        if (res.status === 200) {
                            if (res.data.data.result && res.data.data.result.length > 0) {
                                fetchedKnowledgeItems.push(res.data.data.result[0]);
                            }
                        }
                    } catch (innerError) {
                        console.error(`Error fetching knowledge article ${ref}:`, innerError);
                    }
                }

                setKnowledgeBase(fetchedKnowledgeItems);
            } catch (error) {
                console.error("Error in knowledge base fetching process:", error);
            } finally {
                setLoading(false);
            }
        };

        if (msg) {
            try {
                // Try to parse the message if it's in JSON format
                let parsedMsg;
                if (typeof msg === 'string') {
                    // Check if the message is wrapped in markdown code block
                    if (msg.startsWith('```json') && msg.endsWith('```')) {
                        // Extract the JSON part
                        const jsonContent = msg.replace(/```json\n|\n```/g, '');
                        parsedMsg = JSON.parse(jsonContent);
                    } else {
                        // Try parsing directly in case it's a JSON string
                        try {
                            parsedMsg = JSON.parse(msg);
                        } catch (e) {
                            // It's a plain string, not JSON
                            parsedMsg = { msg, references: [] };
                        }
                    }
                } else {
                    // Message is already an object
                    parsedMsg = msg;
                }

                // Check if references array exists and is not empty
                if (parsedMsg.references && Array.isArray(parsedMsg.references) && parsedMsg.references.length > 0) {
                    fetchKnowledgeBaseItems(parsedMsg.references);
                }
            } catch (error) {
                console.error("Error parsing message:", error);
            }
        }
    }, [msg]);
    */

    // Function to handle KB attachment
    const attachToKB = (kbNumber) => {
        if (attach && typeof attach === 'function') {
            attach(kbNumber);
        }
    };

    // Pagination logic for short description incidents
    const shortDescTotal = relatedIncidents.shortDesc ? relatedIncidents.shortDesc.length : 0;
    const shortDescTotalPages = Math.ceil(shortDescTotal / itemsPerPage);
    const shortDescStartIndex = (shortDescPage - 1) * itemsPerPage;
    const shortDescEndIndex = shortDescStartIndex + itemsPerPage;
    const paginatedShortDescIncidents = relatedIncidents.shortDesc ?
        relatedIncidents.shortDesc.slice(shortDescStartIndex, shortDescEndIndex) : [];

    // Pagination logic for cmdbCI incidents
    const cmdbCITotal = relatedIncidents.cmdbCI ? relatedIncidents.cmdbCI.length : 0;
    const cmdbCITotalPages = Math.ceil(cmdbCITotal / itemsPerPage);
    const cmdbCIStartIndex = (cmdbCIPage - 1) * itemsPerPage;
    const cmdbCIEndIndex = cmdbCIStartIndex + itemsPerPage;
    const paginatedCmdbCIIncidents = relatedIncidents.cmdbCI ?
        relatedIncidents.cmdbCI.slice(cmdbCIStartIndex, cmdbCIEndIndex) : [];

    // Pagination logic for short description knowledge
    const shortDescKnowledgeTotal = knowledgeBase.shortDesc ? knowledgeBase.shortDesc.length : 0;
    const shortDescKnowledgeTotalPages = Math.ceil(shortDescKnowledgeTotal / itemsPerPage);
    const shortDescKnowledgeStartIndex = (shortDescKnowledgePage - 1) * itemsPerPage;
    const shortDescKnowledgeEndIndex = shortDescKnowledgeStartIndex + itemsPerPage;
    const paginatedShortDescKnowledge = knowledgeBase.shortDesc ?
        knowledgeBase.shortDesc.slice(shortDescKnowledgeStartIndex, shortDescKnowledgeEndIndex) : [];

    // Pagination logic for cmdbCI knowledge
    const cmdbCIKnowledgeTotal = knowledgeBase.cmdbCI ? knowledgeBase.cmdbCI.length : 0;
    const cmdbCIKnowledgeTotalPages = Math.ceil(cmdbCIKnowledgeTotal / itemsPerPage);
    const cmdbCIKnowledgeStartIndex = (cmdbCIKnowledgePage - 1) * itemsPerPage;
    const cmdbCIKnowledgeEndIndex = cmdbCIKnowledgeStartIndex + itemsPerPage;
    const paginatedCmdbCIKnowledge = knowledgeBase.cmdbCI ?
        knowledgeBase.cmdbCI.slice(cmdbCIKnowledgeStartIndex, cmdbCIKnowledgeEndIndex) : [];


    // Custom tab button styles
    const tabButtonStyle = {
        border: 'none',
        padding: '12px 20px',
        background: 'none',
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: '500',
        color: '#666',
        flex: 1,
        textAlign: 'center',
        transition: 'all 0.3s ease',
        outline: 'none',
    };

    const activeTabStyle = {
        ...tabButtonStyle,
        color: theme.palette.primary.main,
        borderBottom: '2px solid ' + theme.palette.primary.main,
        backgroundColor: '#f8f8f8',
        fontWeight: '600',
    };

    const incidentItemStyle = {
        padding: '10px 15px',
        borderBottom: '1px solid #eee',
        cursor: 'pointer',
        transition: 'background-color 0.2s ease',
        ':hover': {
            backgroundColor: '#f5f5f5',
        }
    };

    const sectionHeaderStyle = {
        padding: '12px 15px',
        backgroundColor: '#f5f5f5',
        fontWeight: '600',
        borderBottom: '1px solid #ddd',
        margin: '0',
        fontSize: '14px',
        color: theme.palette.primary.main,
    };

    const paginationContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px 15px',
        backgroundColor: '#f8f8f8',
        borderTop: '1px solid #eee',
    };

    const paginationButtonStyle = {
        padding: '5px 10px',
        margin: '0 5px',
        backgroundColor: 'white',
        border: '1px solid #ddd',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '12px',
        transition: 'all 0.2s ease',
    };

    const activePaginationButtonStyle = {
        ...paginationButtonStyle,
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderColor: theme.palette.primary.main,
    };

    const disabledPaginationButtonStyle = {
        ...paginationButtonStyle,
        opacity: 0.5,
        cursor: 'not-allowed',
    };

    // Pagination control component
    const PaginationControl = ({ currentPage, totalPages, setPage, listType }) => {
        return (
            <div style={paginationContainerStyle}>
                <button
                    style={currentPage === 1 ? disabledPaginationButtonStyle : paginationButtonStyle}
                    onClick={() => setPage(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Prev
                </button>
                <span style={{ margin: '0 10px', fontSize: '12px' }}>
                    Page {currentPage} of {totalPages || 1}
                </span>
                <button
                    style={currentPage === totalPages || totalPages === 0 ? disabledPaginationButtonStyle : paginationButtonStyle}
                    onClick={() => setPage(currentPage + 1)}
                    disabled={currentPage === totalPages || totalPages === 0}
                >
                    Next
                </button>
            </div>
        );
    };

    // Check if we have incidents of either type
    const hasShortDescIncidents = relatedIncidents.shortDesc && relatedIncidents.shortDesc.length > 0;
    const hasCmdbCIIncidents = relatedIncidents.cmdbCI && relatedIncidents.cmdbCI.length > 0;
    const hasNoIncidents = !hasShortDescIncidents && !hasCmdbCIIncidents;

    const hasShortDescKnowledge = knowledgeBase.shortDesc && knowledgeBase.shortDesc.length > 0;
    const hasCmdbCIKnowledge = knowledgeBase.cmdbCI && knowledgeBase.cmdbCI.length > 0;
    const hasNoKnowledge = !hasShortDescKnowledge && !hasCmdbCIKnowledge;

    return (
        <div className="tabbed-container">
            {/* Tab Navigation */}
            <div className="chat-tabs">
                <button
                    style={activeTab === 'chat' ? activeTabStyle : tabButtonStyle}
                    onClick={() => setActiveTab('chat')}
                >
                    Agent Assist
                </button>
                <button
                    style={activeTab === 'incidents' ? activeTabStyle : tabButtonStyle}
                    onClick={() => setActiveTab('incidents')}
                >
                    Related Incidents
                </button>
                <button
                    style={activeTab === 'knowledge' ? activeTabStyle : tabButtonStyle}
                    onClick={() => setActiveTab('knowledge')}
                >
                    Knowledge
                </button>
            </div>

            {/* Tab Content */}
            <div className="tab-content scrollbar-hide">
                {/* Always render the chat content but hide it when not on chat tab */}
                <div className="chat-tab" style={{ display: activeTab === 'chat' ? 'block' : 'none', height: '100%' }}>
                    {children}
                </div>

                {activeTab === 'incidents' && (
                    <div className="incidents-tab">
                        <div className="incidents-list">
                            {hasNoIncidents ? (
                                <div className="incident-item">
                                    <p>No related incidents found.</p>
                                </div>
                            ) : (
                                <>
                                    {/* Short Description Based Incidents Section */}
                                    {hasShortDescIncidents && (
                                        <>
                                            <h3 style={sectionHeaderStyle}>
                                                Short Description-Based Incidents
                                                {shortDescTotal > 0 && <span style={{ fontSize: '12px', fontWeight: 'normal', marginLeft: '8px' }}>
                                                    ({shortDescTotal} found)
                                                </span>}
                                            </h3>
                                            {paginatedShortDescIncidents.map(incident => (
                                                <div key={`desc-${incident.number}`} className="incident-item" style={incidentItemStyle}>
                                                    <ResourceCardMsg inc={incident} />
                                                </div>
                                            ))}
                                            {shortDescTotal > itemsPerPage && (
                                                <PaginationControl
                                                    currentPage={shortDescPage}
                                                    totalPages={shortDescTotalPages}
                                                    setPage={setShortDescPage}
                                                    listType="shortDesc"
                                                />
                                            )}
                                        </>
                                    )}

                                    {/* Configuration Item Based Incidents Section */}
                                    {hasCmdbCIIncidents && (
                                        <>
                                            <h3 style={sectionHeaderStyle}>
                                                Configuration Item-Based Incidents
                                                {cmdbCITotal > 0 && <span style={{ fontSize: '12px', fontWeight: 'normal', marginLeft: '8px' }}>
                                                    ({cmdbCITotal} found)
                                                </span>}
                                            </h3>
                                            {paginatedCmdbCIIncidents.map(incident => (
                                                <div key={`ci-${incident.number}`} className="incident-item" style={incidentItemStyle}>
                                                    <ResourceCardMsg inc={incident} />
                                                </div>
                                            ))}
                                            {cmdbCITotal > itemsPerPage && (
                                                <PaginationControl
                                                    currentPage={cmdbCIPage}
                                                    totalPages={cmdbCITotalPages}
                                                    setPage={setCmdbCIPage}
                                                    listType="cmdbCI"
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                )}


                {activeTab === 'knowledge' && (
                    <div className="knowledge-tab">
                        <div className="knowledge-list">
                            {knowledgeLoading ? (
                                <div className="knowledge-item">
                                    <p>Loading knowledge base items...</p>
                                </div>
                            ) : hasNoKnowledge ? (
                                <div className="knowledge-item">
                                    <p>No knowledge base articles found.</p>
                                </div>
                            ) : (
                                <>
                                    {/* Short Description Based Knowledge Section */}
                                    {hasShortDescKnowledge && (
                                        <>
                                            <h3 style={sectionHeaderStyle}>
                                                Short Description-Based Knowledge
                                                {shortDescKnowledgeTotal > 0 && <span style={{ fontSize: '12px', fontWeight: 'normal', marginLeft: '8px' }}>
                                                    ({shortDescKnowledgeTotal} found)
                                                </span>}
                                            </h3>
                                            {paginatedShortDescKnowledge.map(article => (
                                                <div key={`desc-kb-${article.number}`} className="knowledge-item" style={incidentItemStyle}>
                                                    <ResourceCardMsgMemo kb={article} attach={attachToKB} />
                                                </div>
                                            ))}
                                            {shortDescKnowledgeTotal > itemsPerPage && (
                                                <PaginationControl
                                                    currentPage={shortDescKnowledgePage}
                                                    totalPages={shortDescKnowledgeTotalPages}
                                                    setPage={setShortDescKnowledgePage}
                                                    listType="shortDescKnowledge"
                                                />
                                            )}
                                        </>
                                    )}

                                    {/* Configuration Item Based Knowledge Section */}
                                    {hasCmdbCIKnowledge && (
                                        <>
                                            <h3 style={sectionHeaderStyle}>
                                                Configuration Item-Based Knowledge
                                                {cmdbCIKnowledgeTotal > 0 && <span style={{ fontSize: '12px', fontWeight: 'normal', marginLeft: '8px' }}>
                                                    ({cmdbCIKnowledgeTotal} found)
                                                </span>}
                                            </h3>
                                            {paginatedCmdbCIKnowledge.map(article => (
                                                <div key={`ci-kb-${article.number}`} className="knowledge-item" style={incidentItemStyle}>
                                                    <ResourceCardMsgMemo kb={article} attach={attachToKB} />
                                                </div>
                                            ))}
                                            {cmdbCIKnowledgeTotal > itemsPerPage && (
                                                <PaginationControl
                                                    currentPage={cmdbCIKnowledgePage}
                                                    totalPages={cmdbCIKnowledgeTotalPages}
                                                    setPage={setCmdbCIKnowledgePage}
                                                    listType="cmdbCIKnowledge"
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

// Main ChatWidget Component
const ChatbotMemo = memo(Chatbot);

function ChatWidget(params) {
    const [rendered, setRendered] = useState(false);
    const renderRef = useRef(rendered);
    const [relatedIncidents, setRelatedIncidents] = useState({ shortDesc: [], cmdbCI: [] });
    const actionProviderRef = useRef(null);
    const [showChat, setShowChat] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [initialMessages, setInitialMessages] = useState([]);
    const [isBotTyping, setIsBotTyping] = useState(false);
    const [knowledgeBase, setKnowledgeBase] = useState({ shortDesc: [], cmdbCI: [] });
    const [knowledgeLoading, setKnowledgeLoading] = useState(false);

    console.log("bot invoked>>> ", params);

    const attachToInc = (kb) => {
        params.attachment(kb);
    };

    let botDisplayName = '';
    const [open, setOpen] = useState(false);
    const handleOpen = () => { setOpen(true); localStorage.setItem('conversation_id', uuidv4()) };
    const handleClose = (event) => {
        console.log('reason>>>>> ', event);
        setOpen(false);
    };
    localStorage.setItem('botName', params.botName);

    const handleGetAssistance = () => {
        setIsLoading(true);

        // Simulate bot thinking for a couple seconds
        setTimeout(() => {
            setIsLoading(false);
            setShowChat(true);

            // If there's entity data, simulate typing before showing the message
            if (params.entity) {
                setIsBotTyping(true);
                // Simulate typing for 1.5 seconds
                setTimeout(() => {
                    setIsBotTyping(false);
                }, 1500);
            }
        }, 2000);
    };

    // Add this new function to ChatWidget
    const fetchRelatedItems = async (itemType) => {
        const hostName = 'https://kogniv-iservice-gvgmhpeuf5eaaghu.centralus-01.azurewebsites.net/api/main';

        try {
            // Set appropriate loading state based on item type
            if (itemType === 'knowledge') {
                setKnowledgeLoading(true);
            }

            // Get short description and cmdbCI from localStorage
            let shortDesc = localStorage.getItem('shortDescKA');
            let cmdbCI = localStorage.getItem('CIKA');

            // Initialize arrays to store both types of results
            const shortDescItems = [];
            const cmdbCIItems = [];

            // Process based on short description if available
            if (shortDesc) {
                // Extract keywords using retext    
                let keywords = await extractKeywords(shortDesc);
                console.log(`Original keywords for ${itemType} search:`, keywords);

                // Split any keywords containing spaces into separate keywords
                const expandedKeywords = [];
                keywords.forEach(keyword => {
                    if (keyword.includes(' ')) {
                        // If keyword has spaces, split it and add each part
                        const parts = keyword.split(/\s+/).filter(part => part.length > 0);
                        expandedKeywords.push(...parts);
                    } else {
                        // If no spaces, add as is
                        expandedKeywords.push(keyword);
                    }
                });

                // Remove duplicates if any were created during splitting
                keywords = [...new Set(expandedKeywords)];
                console.log(`Expanded keywords for ${itemType} search:`, keywords);

                // Build the query based on item type
                let query = '';
                for (const [i, word] of keywords.entries()) {
                    const term = word.toLowerCase();
                    // Still encode the term for any other special characters
                    const encodedTerm = encodeURIComponent(term);

                    if (i !== 0) {
                        query += `^OR`;
                    }

                    if (itemType === 'incident') {
                        query += `short_descriptionLIKE${encodedTerm}^OR` +
                            `descriptionLIKE${encodedTerm}^OR` +
                            `comments_and_work_notesLIKE${encodedTerm}^OR` +
                            `close_notesLIKE${encodedTerm}`;
                    } else if (itemType === 'knowledge') {
                        query += `short_descriptionLIKE${encodedTerm}^OR` +
                            `textLIKE${encodedTerm}`;
                    }
                }

                // Log the query for debugging
                console.log(`${itemType} query for short description:`, query);

                // Prepare data for API call
                let shortDescData = {
                    "appID": 1,
                    "params": {
                        "data": null,
                        "sysId": null,
                        "entity": itemType, // 'incident' or 'knowledge'
                        "methodType": "get",
                        "query": {
                            "sysparm_query": {
                                "search": query,
                                // Only include state for incidents
                                ...(itemType === 'incident' ? { "state": 7 } : {"workflow_state":"published"})
                            },
                            "sysparm_fields": itemType === 'incident'
                                ? "number,short_description,description,comments_and_work_notes,close_notes,sys_created_on,state"
                                : "number,short_description,text,published"
                        }
                    }
                };

                // Make API call
                let shortDescRes = await axios.post(`${hostName}`, shortDescData);

                if (shortDescRes.status === 200) {
                    const descResults = shortDescRes.data.data.result || [];

                    if (itemType === 'incident') {
                        // Process incident results
                        shortDescItems.push(...processIncidentResults(descResults));
                    } else {
                        // For knowledge articles, no special processing needed
                        shortDescItems.push(...descResults);
                    }
                }
            }

            // Process based on cmdbCI if available
            if (cmdbCI) {
                console.log(`Searching for ${itemType} with cmdbCI:`, cmdbCI);
                const ciValue = cmdbCI.trim();
                const encodedCIValue = encodeURIComponent(ciValue);

                // Build query based on item type
                let ciQuery = '';

                if (itemType === 'incident') {
                    ciQuery = `cmdb_ciLIKE${encodedCIValue}^OR` +
                        `short_descriptionLIKE${encodedCIValue}^OR` +
                        `descriptionLIKE${encodedCIValue}^OR` +
                        `comments_and_work_notesLIKE${encodedCIValue}^OR` +
                        `close_notesLIKE${encodedCIValue}`;
                } else if (itemType === 'knowledge') {
                    ciQuery = `short_descriptionLIKE${encodedCIValue}^OR` +
                        `textLIKE${encodedCIValue}`;
                }

                // Log the encoded query for debugging
                console.log(`${itemType} query for CMDB CI:`, ciQuery);

                // Prepare data for API call
                let cmdbCIData = {
                    "appID": 1,
                    "params": {
                        "data": null,
                        "sysId": null,
                        "entity": itemType, // 'incident' or 'knowledge'
                        "methodType": "get",
                        "query": {
                            "sysparm_query": {
                                "search": ciQuery,
                                // Only include state for incidents
                                ...(itemType === 'incident' ? { "state": 7 } : {})
                            },
                            "sysparm_fields": itemType === 'incident'
                                ? "number,short_description,description,comments_and_work_notes,close_notes,sys_created_on,state"
                                : "number,short_description,text,published"
                        }
                    }
                };

                // Make API call
                let cmdbCIRes = await axios.post(`${hostName}`, cmdbCIData);

                if (cmdbCIRes.status === 200) {
                    const ciResults = cmdbCIRes.data.data.result || [];

                    // Process the results differently based on item type
                    if (itemType === 'incident') {
                        cmdbCIItems.push(...processIncidentResults(ciResults));
                    } else {
                        cmdbCIItems.push(...ciResults);
                    }
                }
            }

            // Deduplicate - remove from cmdbCI if they appear in shortDesc
            const shortDescNumbers = shortDescItems.map(item => item.number);
            const uniqueCmdbCIItems = cmdbCIItems.filter(
                item => !shortDescNumbers.includes(item.number)
            );

            // Set state based on item type
            const categorizedItems = {
                shortDesc: shortDescItems,
                cmdbCI: uniqueCmdbCIItems
            };

            if (itemType === 'incident') {
                setRelatedIncidents(categorizedItems);
                console.log("Related incidents:", categorizedItems);
            } else if (itemType === 'knowledge') {
                setKnowledgeBase(categorizedItems);
                console.log("Knowledge articles:", categorizedItems);
            }

        } catch (error) {
            console.error(`Error fetching related ${itemType}:`, error);

            // Set appropriate empty state based on item type
            if (itemType === 'incident') {
                setRelatedIncidents({ shortDesc: [], cmdbCI: [] });
            } else if (itemType === 'knowledge') {
                setKnowledgeBase({ shortDesc: [], cmdbCI: [] });
            }
        } finally {
            // Reset loading state for knowledge
            if (itemType === 'knowledge') {
                setKnowledgeLoading(false);
            }
        }
    };

    // Helper function to process incident results
    const processIncidentResults = (incidents) => {
        return incidents.map(inc => {
            // Create a copy to avoid mutating the original object
            const processedInc = { ...inc };

            if (processedInc.comments_and_work_notes) {
                processedInc.comments_and_work_notes = processedInc.comments_and_work_notes.replace('\n\n', '\n');
                let tempworkNotes = processedInc.comments_and_work_notes.split('\n');
                let workNotes = [];
                let note = {};

                tempworkNotes = tempworkNotes.filter(e => e !== '');

                for (const [i, row] of tempworkNotes.entries()) {
                    if (i % 2 === 0) {
                        note = {};
                        note.date = row;
                    } else {
                        note.text = row;
                        workNotes.push(note);
                    }
                }

                processedInc.workNotes = workNotes;
            } else {
                processedInc.workNotes = [];
            }

            return processedInc;
        });
    };

    const extractKeywords = async (text) => {
        try {
            // Import required modules
            const { retext } = await import('retext');
            const retextKeywords = await import('retext-keywords');
            const retextPos = await import('retext-pos');
            const { toString } = await import('nlcst-to-string');

            // If text is empty or undefined, return empty array
            if (!text) return [];

            // Process the text
            const file = await retext()
                .use(retextPos.default) // Part-of-speech tagging
                .use(retextKeywords.default)
                .process(text);

            // Extract keyphrases
            const keyphrases = file.data.keyphrases || [];

            // Extract keywords
            const keywords = file.data.keywords || [];

            // Combine extracted phrases and keywords
            const extractedTerms = [
                // Get phrases
                ...keyphrases.map(phrase =>
                    phrase.matches[0].nodes.map(node => toString(node)).join('')
                ),
                // Get individual keywords
                ...keywords.map(keyword =>
                    toString(keyword.matches[0].node)
                )
            ];

            // Filter out duplicates and return
            return [...new Set(extractedTerms)];
        } catch (error) {
            console.error("Error in keyword extraction:", error);
            return []; // Return empty array in case of error
        }
    };

    useEffect(() => {
        let entity = null;
        const styleElement = document.createElement('style');
        if (params.botName === 'employeeAssist') {
            entity = params.entity;
            styleElement.innerHTML = `
        .react-chatbot-kit-chat-container {
          height: calc(-100px + 100vh);
        }
      `;
        } else {
            entity = params.entity;
        }
        console.log("short desc changed", entity);
        let msg = entity;
        if (entity != null && entity != '') {
            setOpen(true);
        }

        // Initialize knowledgeAssist bot with related incidents
        if (params.botName === 'knowledgeAssist') {
            fetchRelatedItems('incident');
            fetchRelatedItems('knowledge');
            styleElement.innerHTML = `
        .react-chatbot-kit-chat-container {
          height: calc(-145px + 100vh);
        }
          .react-chatbot-kit-chat-header {
            display: none;
        }
      `;
        }
        document.head.appendChild(styleElement);
        return () => {
            document.head.removeChild(styleElement);
        };
    }, [params.botName, params.entity]);

    if (params.botName === 'employeeAssist') {
        botDisplayName = 'Employee Assist';
    } else if (params.botName === 'knowledgeAssist') {
        botDisplayName = 'Agent Assist';
    }

    const loadMessages = () => {
        let messages = null;
        console.log("history messages invoked:::");
        if (params.botName === 'employeeAssist') {
            messages = [];
            let user = localStorage.getItem('user');
            if (typeof user === 'string') {
                user = JSON.parse(user);
                if (typeof user === 'string') {
                    user = JSON.parse(user);
                    console.log(user);
                }
            }
            messages.push(createChatBotMessage(`Hello ${user.first_name} ${user.last_name}, how may I assist you?`));
        } else if (params.botName === 'knowledgeAssist') {
            messages = [];
        }
        if (messages === '' || messages === null) {
            messages = [];
        }

        console.log("messages in load messages:::: ", messages);
        return messages;
    };

    const saveMessages = (messages, HTMLString) => {
        console.log("saving message:::", messages);
        let tempMsgs = [];
        if (messages && messages.length > 0) {
            for (const [i, row] of messages.entries()) {
                if (row.message && row.message != '') {
                    tempMsgs.push(row);
                }
            }
        }
        messages = tempMsgs;
    };

    // Update config to use the custom container - only for knowledgeAssist
    const knowledgeAssistConfig = {
        ...config,
        customComponents: {
            ...config.customComponents,
            botChatMessage: config.customComponents?.botChatMessage,
            userChatMessage: config.customComponents?.userChatMessage,
            // Add our custom container only for knowledgeAssist
            chatbotContainer: (props) => (
                <TabbedContainer
                    {...props}
                    botName={params.botName}
                    relatedIncidents={relatedIncidents}
                    knowledgeBase={knowledgeBase}
                    knowledgeLoading={knowledgeLoading}
                    msg={params.entity}
                    attach={attachToInc}
                />
            )
        },
    };

    // Use standard config for employeeAssist
    const employeeAssistConfig = {
        ...config,
        customComponents: {
            ...config.customComponents,
            botChatMessage: config.customComponents?.botChatMessage,
            userChatMessage: config.customComponents?.userChatMessage,
        },
    };

    const getAssistanceBtnStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: '20px',
        backgroundColor: '#f5f5f5',
        borderRadius: '8px',
        margin: '16px'
    };

    return (
        <div style={{ height: 'calc(100vh - 100px)' }}>
            <CssBaseline />
            <Box sx={styles.chatModal}>

                {params.botName === 'knowledgeAssist' &&
                    <TabbedContainer
                        botName={params.botName}
                        relatedIncidents={relatedIncidents}
                        knowledgeBase={knowledgeBase}
                        knowledgeLoading={knowledgeLoading}
                        msg={params.entity}
                        attach={attachToInc}>
                        {!showChat ? (
                            <div style={getAssistanceBtnStyle}>
                                <h2>Agent Assistance</h2>
                                <p>Click the button below to get AI assistance with this ticket</p>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleGetAssistance}
                                    disabled={isLoading}
                                    sx={{ mt: 2 }}
                                >
                                    {isLoading ? (
                                        <>
                                            <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                                            Preparing...
                                        </>
                                    ) : (
                                        'Get Assistance'
                                    )}
                                </Button>
                            </div>
                        ) : (
                            <ChatbotMemo className={styles.reactchatbotkitchatbotmessage}
                                headerText={botDisplayName}
                                config={knowledgeAssistConfig}
                                messageHistory={loadMessages()}
                                actionProvider={memo((props) => {
                                    const actionProvider = <ActionProvider {...props} attach={attachToInc} msg={params.entity} botName={params.botName} />;
                                    // Store action provider in ref for later use
                                    actionProviderRef.current = actionProvider;
                                    return actionProvider;
                                })}
                                messageParser={MessageParser}
                                saveMessages={saveMessages}
                            />
                        )}
                    </TabbedContainer>}
                {params.botName === 'employeeAssist' &&
                    <ChatbotMemo className={styles.reactchatbotkitchatbotmessage}
                        headerText={botDisplayName}
                        config={employeeAssistConfig}
                        messageHistory={loadMessages()}
                        actionProvider={memo((props) => {
                            const actionProvider = <ActionProvider {...props} msg={params.entity} botName={params.botName} />;
                            // Store action provider in ref for later use
                            actionProviderRef.current = actionProvider;
                            return actionProvider;
                        })}
                        messageParser={MessageParser}
                        saveMessages={saveMessages}
                    />}
            </Box>
        </div>
    );
}

export default memo(ChatWidget);
